import './TestimoniosSection.scss';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import logo_educacion from '../../assets/images/logo_educacion.png';
import logo_unal from '../../assets/images/logo_unal.png';
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import testimony1F from '../../assets/images/NARIÑO_F.webp';
import testimony2F from '../../assets/images/ANDINA_F.webp';
import testimony1E from '../../assets/images/CARIBE_E.webp';
import testimony2E from '../../assets/images/NARIÑO_E.webp';
import testimony3E from '../../assets/images/EJE_CAFETERO_E.webp';
import directivos from '../../assets/images/img_directivos.webp';
import testimony1 from '../../assets/images/testimony_1.webp';
import testimony3 from '../../assets/images/testimony_3.webp';

const CardTestimonios = ({ title, img, imgDesc, autor, action, testimonio, type, pagina }: any) => {

  return (
      <>  
          <Grid container className='card-testimonios-container' onClick={action}>
            <Grid container item xs={12} className='img-item'>
              <img src={img} alt={imgDesc} className='info-img' />
            </Grid>
            <Grid container item xs={12} className='title-container'>
              <Typography className='title'>
                {title}
              </Typography>
            </Grid>
            <Grid container item xs={12} className='text-container'>
              <Typography className='text'>
                {autor}
              </Typography>
            </Grid>
            <div className='button-container'>
              <Link to={`/${pagina}/testimonios/${testimonio}?query=${type}`}>
                <Button variant="contained" fullWidth className='testimony-button'>
                    <Typography className='btn-txt'>
                      Ver testimonio
                    </Typography>
                </Button>
              </Link>
            </div>
          </Grid>
      </>
  );
}

export const TestimoniosSection = () => {

  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const section = searchParams.get("query");

  const testimonios_transformacion = [
    { title: "Transformando la educación a través de la participación activa en Arauca: Entrevista con Lourdes Medina",
      video: "https://drive.google.com/file/d/1MSQFe_Zcd4UWWkQdnfI_-eREFPIPaPPT/preview",
      content: <><b>Transformando la educación en Arauca</b><br/>
        Descubre cómo la participación activa potencia el aprendizaje.<br/><br/>
        <b>Un enfoque integral para el aprendizaje</b><br/>
        Es importante ver este video donde la docente Lourdes Medina, coordinadora de la Institución Educativa Gustavo Villa Díaz, nos comparte desde su experiencia la importancia de dar a los estudiantes la oportunidad de participar activamente en su propio proceso de aprendizaje. Al hacerlo, no solo se fomenta su autonomía y creatividad, sino que también se potencia su capacidad crítica y su interés por aprender. Cuando los estudiantes son partícipes activos de su educación, desarrollan habilidades que van más allá del simple aprendizaje de contenidos; adquieren competencias sociales, emocionales y cognitivas que los preparan para enfrentar desafíos de manera integral.<br/><br/>
        <b>El rol fundamental del docente</b><br/>
        El rol del docente en este proceso es clave. El educador no solo debe ser un transmisor de conocimientos, sino también un facilitador del aprendizaje, capaz de adaptar su enseñanza a las necesidades y características individuales de cada estudiante. La formulación de nuevas metodologías y enfoques pedagógicos es esencial para lograr este objetivo. Esto incluye estrategias como el aprendizaje basado en proyectos, el trabajo colaborativo y el uso de tecnologías, que permiten involucrar a los estudiantes de manera activa y significativa en su aprendizaje.<br/><br/>
        <b>Educación inclusiva: un principio central</b><br/>
        En este contexto, la educación inclusiva es un principio central, ya que busca garantizar que todos los estudiantes, independientemente de sus diferencias (cognitivas, físicas, emocionales o sociales), tengan acceso a una educación de calidad. La inclusión implica reformular la educación para hacerla más flexible y adaptativa, permitiendo que cada estudiante aprenda a su propio ritmo y según sus propias necesidades. Esto requiere que los docentes se capaciten continuamente para identificar las barreras de aprendizaje y desarrollar estrategias que promuevan la participación plena de todos los estudiantes.<br/><br/>
        <b>Transformando las prácticas pedagógicas</b><br/>
        Transformar las prácticas pedagógicas implica una reconfiguración de la relación entre los docentes y los estudiantes. La enseñanza pasa de ser un proceso unidireccional a una interacción más dialógica y activa. En lugar de ser solo transmisores de conocimiento, los maestros y maestras se convierten en apoyos, facilitadores, mentores y modelos a seguir, promoviendo un aprendizaje más humanizado y respetuoso con las individualidades de cada estudiante.<br/><br/>
        <b>El impacto positivo de la participación activa</b><br/>
        El impacto positivo en los conocimientos adquiridos por los estudiantes también les proporciona las herramientas necesarias para desenvolverse con confianza, equidad y éxito en un mundo cada vez más diverso y dinámico. Al participar activamente en su proceso de aprendizaje, los estudiantes desarrollan habilidades esenciales como el pensamiento crítico, la resolución de problemas, la colaboración y la empatía, lo cual los prepara para enfrentar los retos del futuro de manera integral.<br/><br/>
        <b>El papel de los docentes como agentes de cambio</b><br/>
        Por otro lado, los docentes desempeñan un papel clave en este proceso de transformación. Son ellos quienes, al reflexionar sobre su práctica y ser capaces de innovar, pueden crear un ambiente de aprendizaje más inclusivo, flexible y adaptado a las necesidades de todos los estudiantes. Los maestros no solo deben enseñar contenidos, sino también ser agentes de cambio que promuevan una educación más equitativa, que valore la diversidad y permita a cada estudiante alcanzar su máximo potencial.<br/><br/>
      </>,
      autor: "Por Alejandro García Gómez",
      orientation: "vertical"
    },
    { title: "Desafíos y estrategias para superar barreras en grupos vulnerables de Aguachica, Cesar",
      video: "https://drive.google.com/file/d/1LRvq8Rd55N3fKV7wOyOLLjgPcgy0-6T9/preview",
      content: <><b>Desafíos y estrategias en Aguachica, Cesar</b><br/>
        Reflexiones sobre la educación inclusiva y la equidad en grupos vulnerables de Aguachica.<br/><br/>
        La conmemoración del 25° aniversario de la Conferencia Mundial sobre Necesidades Educativas Especiales, celebrada en Salamanca, fue un evento crucial que se visibilizó en el Foro Internacional sobre Equidad e Inclusión en la Educación de la UNESCO – "Todas y todos los estudiantes cuentan", realizado en Cali en 2020. En este foro, se discutieron los desafíos y las estrategias que aún persisten para aquellos grupos humanos que enfrentan dificultades y barreras contextuales, las cuales dificultan su paso de la vulnerabilidad hacia "sistemas educativos que no dejen a nadie atrás". Este es, como sabemos, uno de los objetivos del Desarrollo Sostenible dentro de la Agenda 2030, tal como se destacó en el foro.<br/><br/>
        Retomando los desafíos de la Agenda 2030, se busca brindar oportunidades para sociedades más inclusivas, en las que reinen la justicia y la equidad. En este contexto, y con el objetivo de brindar herramientas a docentes, directivos y orientadores escolares, se trabaja con este componente dentro del marco del convenio entre el MEN y la Universidad Nacional de Colombia, enfocado en la "Implementación de estrategias que promuevan la educación inclusiva para niñas, niños, adolescentes, jóvenes y adultos".<br/><br/>
        En este sentido, se reunió a un grupo representativo del Cesar en el municipio de Aguachica, con quienes se trabajó el documento, se dialogó y se reflexionó sobre la estrategia. La actividad fue muy enriquecedora, escuchándose voces de alegría, compromiso y aprendizaje. A partir de este trabajo mancomunado, surge la siguiente declaración, que el grupo de participantes asume con mucho empoderamiento, participación, empatía y convicción.<br/><br/>
        <b>Declaratoria del grupo de participantes:</b><br/>
        "A nombre de mis compañeros, directivos docentes, docentes de aula, docentes de apoyo pedagógico y docentes orientadores del Cesar, ratificamos los principios de la educación inclusiva, fundamentada en la calidad, equidad, interculturalidad, pertenencia, participación y diversidad. Reconocemos los desafíos que enfrentamos en esta labor, tales como promover la inclusión, el respeto, el disfrute de los derechos, la empatía y la concientización. Asimismo, declaramos nuestro compromiso de continuar transformando las prácticas educativas mediante la divulgación y el intercambio de experiencias".<br/><br/>
        Tales declaraciones ratifican el papel de cada uno de los participantes, ya sea en su rol administrativo, docente o de orientador escolar, en los diferentes procesos en el ámbito estudiantil, docente y con los padres de familia. Este documento reflexivo, enmarcado en la portada "Nuestras Declaraciones Cuentan" y la "Declaración del Manifiesto Docente", promueve y garantiza una educación inclusiva, equitativa y de calidad. Los declarantes se comprometen, desde sus respectivas funciones, a dar cumplimiento a estos principios.<br/><br/>
        Este desafío representa un avance en la comprensión de la educación inclusiva y los Objetivos del Desarrollo Sostenible, al hacer un llamado a los sistemas educativos para "garantizar una educación inclusiva, equitativa y de calidad y promover oportunidades de aprendizaje a lo largo de toda la vida para todas las personas sin excepción".<br/>
        Lo importante de este momento es servir de puente comunicativo con todas las comunidades, de modo que lo trabajado en este momento de diálogo y reflexión se refleje en escenarios amplios y permanentes de diálogo, la transformación de prácticas, culturas y políticas, buscando garantizar una educación que reconozca, valore y responda a todas las personas.
      </>,
      autor: "Por Mónica Buelvas",
      orientation: "horizontal"
    },
    { title: "Educación inclusiva: una mirada al pasado, presente y futuro desde el Eje Cafetero",
      video: "https://drive.google.com/file/d/1dvuK-4xfpAQ7Su-RoKivWFLxZhZjTkFS/preview",
      content: <><b>Educación inclusiva: pasado, presente y futuro desde el Eje Cafetero</b><br/>
      Reflexión sobre la evolución de la educación inclusiva y su impacto en el Eje Cafetero.<br/><br/>
      En el video, uno de los participantes comparte su historia de vida, relatando una experiencia vivida en su tiempo como estudiante de tercer grado de primaria. A través de esta narración, se evidencia cómo algunas realidades educativas que él experimentó, aún persisten en las aulas escolares, a pesar de que el tiempo ha avanzado y la sociedad y el lenguaje han evolucionado. A lo largo de los años, los requerimientos educativos de los estudiantes con discapacidad, talentos excepcionales, trastornos del aprendizaje o comportamiento, o que requieren atención académica por condiciones de salud, han existido siempre, aunque muchas veces se han visto silenciados por culturas, políticas y prácticas educativas que desconocen estas realidades. Este relato nos invita a cuestionar cómo abordamos estos contextos y, más importante aún, qué tan comprometidos estamos en construir una educación inclusiva que respete la diversidad y garantice equidad para todos.<br/><br/>
      El video narra la historia de un estudiante de tercer grado, quien es "etiquetado" por sus docentes y trasladado a un grado inferior. Este hecho obliga a reflexionar sobre cómo las diferencias en los requerimientos de aprendizaje se han interpretado históricamente como déficits. Es crucial reconocer que la equidad no consiste en dar lo mismo a todas las personas, sino en ofrecer a cada estudiante lo que necesita para alcanzar su máximo potencial. En un sistema inclusivo, ningún estudiante debería ser definido por sus desafíos, sino apoyado para superar barreras. Esto exige formación docente, recursos adecuados y, sobre todo, una pedagogía que valore a cada estudiante en su individualidad.<br/><br/>
      La educación inclusiva no es solo un ideal, sino un derecho. Garantizar que todos los estudiantes, independientemente de sus capacidades, sean valorados y participen plenamente en el proceso educativo requiere un cambio de perspectiva. No se trata de adaptar al niño al sistema, sino de transformar el sistema educativo para que sea accesible a toda la población. Esto debe suceder a lo largo de toda su trayectoria educativa, desde la primera infancia, ya que cada estudiante tiene algo único que aportar a la comunidad escolar.<br/><br/>
      La educación inclusiva debe ir de la mano con la calidad. No basta con garantizar solo el acceso a la educación; también es necesario que el proceso de enseñanza sea significativo, relevante y adaptado a los requerimientos de los estudiantes. Esto incluye ajustes razonables, estrategias pedagógicas ajustadas, evaluaciones adecuadas y un acompañamiento constante que promueva un aprendizaje efectivo.<br/><br/>
      Las metodologías innovadoras, que a menudo están apoyadas en la tecnología, se presentan como recursos clave para la educación inclusiva. Las herramientas digitales pueden abrir puertas para que los estudiantes creen, se comuniquen, aprendan y participen de formas que antes eran inimaginables. Los enfoques innovadores también deben incluir metodologías como el aprendizaje basado en proyectos o estrategias multisensoriales que fomenten la participación y el aprendizaje significativo con criterios de diseño universal.<br/><br/>
      El compromiso conjunto es la base de la transformación educativa. Este es un llamado a que cada docente de aula, padre de familia, directivo docente y los demás miembros de la comunidad educativa, construyan un entorno en el que ningún estudiante se sienta excluido o señalado. Esto requiere una transformación en las culturas, políticas y prácticas educativas, que vayan en contra de los prejuicios. Invertir en la formación continua y trabajar de manera articulada son pasos esenciales para que el aula sea un espacio de respeto y empatía.<br/><br/>
      Finalmente, en el relato se recuerda que el impacto de una experiencia educativa trasciende la niñez y moldea la vida adulta. Los niños y niñas que no reciben la atención adecuada no solo enfrentan consecuencias académicas, sino también emocionales. Por lo tanto, el compromiso con la educación inclusiva es una responsabilidad compartida entre toda la sociedad, empezando por pequeños actos de escuchar, comprender y actuar con empatía.
      </>,
      autor: "Por Olga Lucía López",
      orientation: "horizontal"
    },
    { title: "La esencia de la docencia transformadora en Nariño",
      video: "https://drive.google.com/file/d/1MVBwns1q7DEZu81ksp9-C19_kLyw08Jg/preview",
      content: <><b>La esencia de la docencia transformadora en Nariño</b><br/>
      Docentes de Nariño y Putumayo reflexionan sobre la flexibilidad, capacitación y educación inclusiva.<br/><br/>
      En el relato de los y las docentes de entidades territoriales de Nariño y Putumayo, se encapsula la esencia misma de la docencia transformadora. Sus palabras revelan un compromiso profundo con la educación, no solo como una labor, sino como una vocación que trasciende las aulas. Al hablar de flexibilidad, capacitación continua y educación inclusiva, se está delineando un camino hacia una práctica pedagógica que coloca al estudiante en el centro del proceso educativo, reconociendo sus particularidades y potenciando sus capacidades al máximo.<br/><br/>
      La flexibilidad, en el contexto docente, va más allá de adaptar las clases a los imprevistos. Se trata de una actitud mental que permite reconfigurar los objetivos, los métodos, los materiales y las evaluaciones para responder a las particularidades cambiantes de los estudiantes. En un mundo en constante evolución, donde la tecnología y las demandas sociales se transforman a un ritmo acelerado, la capacidad de adaptarse es esencial para garantizar que la educación siga siendo relevante y significativa.<br/><br/>
      La capacitación continua, por su parte, es el motor que impulsa el crecimiento profesional. Al mantenernos actualizados y buscar la innovación en el aula a través de tendencias pedagógicas, estamos mejor equipados para ofrecer a nuestros estudiantes las herramientas y los conocimientos que necesitan para enfrentar los desafíos de la actualidad. La educación inclusiva, en este sentido, representa un campo en constante expansión, con nuevas estrategias y enfoques que surgen cada día para todos los estudiantes.<br/><br/>
      La mención a la que se llega de la importancia de empoderar a los colegas en el proceso de inclusión es un testimonio de liderazgo y la visión de una comunidad educativa unida en torno a un objetivo común. Al compartir sus conocimientos y experiencias, se está creando un ambiente de colaboración y aprendizaje mutuo que beneficia a todos los miembros que participan y hacen parte de las instituciones educativas.<br/><br/>
      La educación inclusiva se enfoca en crear un entorno de aprendizaje donde todos los estudiantes, independientemente de su origen sociocultural, sus habilidades o sus intereses, se sientan valorados, reconocidos y respetados. Al hablar de dar amor, paciencia y tiempo a estos procesos, se está reconociendo la importancia de las relaciones humanas en la educación. Los estudiantes necesitan sentirse conectados con sus docentes y con sus compañeros para desarrollar un sentido de pertenencia y una motivación por aprender.<br/><br/>
      En conclusión, las palabras de los docentes nos inspiran a todos a reflexionar sobre nuestra práctica y a buscar formas de mejorarla. La flexibilidad, la capacitación continua y la educación desde el respeto y la empatía son pilares fundamentales de una educación de calidad. Al trabajar juntos, podemos crear entornos educativos donde todos los estudiantes tengan la oportunidad de participar de forma plena.
      </>,
      autor: "Por Karen Cruz y Lucía Güiza",
      orientation: "horizontal"
    },
    { title: "La educación inclusiva: un compromiso con la diversidad y la equidad en la Región Antioqueña",
      video: "https://drive.google.com/file/d/1I2_7T9LlezzANBvie4O5ClKiyATx9wQ6/preview",
      content: <><b>La educación inclusiva: un compromiso con la diversidad y la equidad en la Región Antioqueña</b><br/>
      Reflexión sobre la equidad, innovación y colaboración en la construcción de una educación inclusiva en Antioquía.<br/><br/>
      La educación inclusiva es un proceso permanente, un desafío que requiere reflexión continua, adaptación y compromiso. Este proceso transforma la manera en que entendemos el aprendizaje, el papel del docente y la función de la comunidad educativa, buscando garantizar que todos los estudiantes, independientemente de sus características, puedan acceder a una educación de calidad.<br/><br/>
      Los principios de equidad, inclusión, calidad, innovación y compromiso son esenciales para este enfoque. Sin embargo, estos deben ir más allá de ser conceptos abstractos o puntos de discurso. Es necesario que se conviertan en prácticas pedagógicas concretas que guíen el trabajo cotidiano de todos los actores educativos, desde los docentes de aula, los docentes de apoyo pedagógico, los profesionales de apoyo y las familias, bajo una premisa clara: valorar, celebrar y responder a las particularidades de cada estudiante.<br/><br/>
      En este contexto, hablar de equidad implica reconocer que cada estudiante tiene necesidades y realidades diferentes. La equidad no se refiere a dar lo mismo a todos, sino a ofrecer a cada estudiante lo que necesita para alcanzar su máximo potencial. Para que todos tengan las mismas oportunidades de éxito, es fundamental proporcionar los recursos y apoyos adecuados centrados en el estudiante.<br/><br/>
      La labor de los docentes de apoyo es clave. Como se destaca en el video, la equidad en la educación exige que cada estudiante reciba el acompañamiento necesario, ya sea a través de adaptaciones curriculares, el uso de tecnologías de apoyo o el diseño de actividades diferenciadas que favorezcan su participación activa y significativa en el aula. Para lograr esto, es crucial fomentar la colaboración entre docentes, familias y otros profesionales educativos. Este trabajo conjunto asegura que todos los estudiantes tengan un lugar dentro del aula, contribuyendo a un ambiente de aprendizaje respetuoso y enriquecedor para todos.<br/><br/>
      En la educación inclusiva, la calidad no debe medirse solo por los resultados académicos, sino por la capacidad del sistema educativo para garantizar que todos los estudiantes tengan la oportunidad de desarrollar sus habilidades y competencias en un entorno justo y accesible. La calidad educativa implica la implementación de metodologías y estrategias pedagógicas que respondan a la diversidad de intereses, gustos, características y experiencias de los estudiantes, adaptando las enseñanzas a sus ritmos y necesidades de aprendizaje.<br/><br/>
      Como señala la directiva docente, una educación inclusiva de calidad debe incorporar recursos, estrategias y enfoques diferenciados que favorezcan el aprendizaje de todos los estudiantes. Esto incluye el uso de tecnologías educativas, el diseño de planes de aprendizaje personalizados, el ajuste del sistema de evaluación y la creación de un ambiente inclusivo que valore la participación de todos.<br/><br/>
      Por otro lado, la innovación lleva a la transformación de las prácticas educativas. El uso y diseño de metodologías activas y colaborativas, como el aprendizaje basado en proyectos o el aprendizaje cooperativo, han demostrado ser efectivas para involucrar a todos los estudiantes y permitirles aprender de manera más dinámica y participativa.<br/><br/>
      La innovación también se extiende al uso de herramientas tecnológicas que faciliten el acceso a la información y al aprendizaje, especialmente para aquellos estudiantes que enfrentan mayores barreras en el contexto educativo.<br/><br/>
      Para que la educación inclusiva sea efectiva, es necesario que todos los actores involucrados en el proceso educativo —docentes de aula, directivos docentes, familias, autoridades educativas y la sociedad en general— asuman un compromiso real y constante. Este compromiso debe ir más allá de la teoría y convertirse en acciones concretas dentro y fuera del aula. El trabajo conjunto entre los docentes y las familias, el acompañamiento a los estudiantes y la adopción de políticas inclusivas son fundamentales para lograr este objetivo.<br/><br/>
      Así, la educación inclusiva no es un fin en sí misma, sino un proceso continuo, como señala la directiva docente. En última instancia, es un llamado a repensar el aula, la escuela y la sociedad, para construir un futuro en el que todos los estudiantes tengan las mismas oportunidades de aprender, crecer y contribuir al desarrollo de su comunidad.
      </>,
      autor: "Por Angelina Beltrán",
      orientation: "vertical"
    },
    { title: "La educación equitativa y de calidad: pilar para el desarrollo integral en Funza, Cundinamarca",
      video: "https://drive.google.com/file/d/1UqF_YnRHCcO98U5AuP5kbDMYZtJmOSEn/preview",
      content: <><b>La educación equitativa y de calidad: pilar para el desarrollo integral en Funza, Cundinamarca</b><br/>
      Reflexión sobre la educación inclusiva y la formación continua para el desarrollo integral.<br/><br/>
      La educación equitativa y de calidad es esencial para el desarrollo integral de niñas, niños, adolescentes y jóvenes, ya que les permite alcanzar su máximo potencial y convertirse en ciudadanos activos en la sociedad. En este sentido, es crucial que la oferta educativa se enfoque en proporcionar experiencias de aprendizaje relevantes y pertinentes, que respondan a las necesidades y expectativas de cada estudiante, permitiéndoles avanzar de manera significativa en su proyecto de vida.<br/><br/>
      La educación inclusiva debe centrarse en el desarrollo de habilidades y competencias que permitan a cada persona explorar su entorno sin restricciones, asumir de forma crítica sus experiencias personales y colectivas, resolver los retos que se le presenten con tranquilidad y construir, de manera colaborativa, espacios de expresión y creación. Estos espacios deben diversificar las formas de ver, sentir y apropiarse de las vivencias cotidianas, tanto dentro como fuera del aula.<br/><br/>
      Es necesario que las instituciones educativas continúen avanzando de manera decidida en la transformación de políticas y prácticas que, en el marco de los derechos humanos, garanticen que la educación sea inclusiva y accesible para todos, sin importar las condiciones particulares de cada estudiante. Esto implica mitigar situaciones de vulneración originadas por la discriminación, la exclusión y otras circunstancias que generen desventajas. Para ello, es fundamental contar con una planeación educativa flexible y adaptable, que ofrezca oportunidades de aprendizaje centradas en la persona, permitiendo su plena participación.<br/><br/>
      La implementación de diversas estrategias en las prácticas educativas permite a los docentes aprovechar mejor la interacción con sus estudiantes. En este contexto, conocer y aplicar el Diseño Universal para el Aprendizaje (DUA) es crucial para garantizar el acceso a la educación, brindando a los estudiantes la posibilidad de elegir las formas que mejor se adapten a sus capacidades y estilos de aprendizaje.<br/><br/>
      La tecnología también juega un papel clave, ya que ofrece herramientas y recursos que facilitan experiencias de aprendizaje interactivas y efectivas. Además, permite que los estudiantes desarrollen habilidades digitales esenciales para el contexto actual. Es fundamental que los docentes estén capacitados para utilizar la tecnología de manera efectiva en el aula y que los estudiantes tengan acceso a dispositivos y recursos digitales adecuados. Asimismo, el uso de la tecnología puede ofrecer apoyos y ajustes razonables, creando un ambiente de aprendizaje común entre los estudiantes, independientemente de sus condiciones.<br/><br/>
      Finalmente, la formación continua de los docentes en temas relacionados con la educación inclusiva es fundamental. Esto les permite identificar las barreras que enfrentan los estudiantes, adaptar sus prácticas pedagógicas y colaborar con otros docentes para asegurar que todos los estudiantes alcancen su máximo potencial y se conviertan en ciudadanos activos y productivos en la sociedad.
      </>,
      autor: "Por Marcela Morera",
      orientation: "vertical"
    },
    { title: "Hacia una educación más inclusiva: Construyendo un sistema educativo para todas y todos en el Valle del Cauca ",
      video: "",
      content: <><b>Hacia una educación más inclusiva: construyendo un sistema educativo para todas y todos en el Valle del Cauca</b><br/>
      Reflexión sobre la educación inclusiva y los desafíos para garantizar una educación equitativa.<br/><br/>
      Este ejercicio resalta la importancia de trabajar hacia un sistema educativo más inclusivo, equitativo y de calidad, que pueda responder de manera efectiva a la diversidad de necesidades que presentan los estudiantes. Es fundamental garantizar que todos, sin excepción, tengan acceso a una educación que promueva su desarrollo integral y su participación activa en la sociedad.<br/><br/>
      En el caso de los estudiantes con discapacidad, se destacó la relevancia de establecer rutas claras, realizar diagnósticos a tiempo y aplicar los ajustes razonables necesarios para su aprendizaje, desarrollo y participación en el ámbito educativo. A pesar de los avances, se identificaron desafíos significativos, como la escasez de recursos humanos especializados, las demoras en los procesos de salud que afectan la continuidad educativa y la necesidad urgente de formación específica para los docentes. Asimismo, se subrayó la importancia de flexibilizar el currículo y de diseñar ambientes de aprendizaje adecuados que promuevan el desarrollo de sus habilidades.<br/><br/>
      Respecto a los estudiantes con talentos o capacidades excepcionales, se resaltó la necesidad de identificar y potenciar sus habilidades a través de estrategias específicas, como el desarrollo de proyectos interdisciplinarios, actividades extracurriculares y programas institucionales que los involucren activamente. Además, se mencionó la importancia de las alianzas estratégicas con otras instituciones y la capacitación de los docentes para atender de manera adecuada sus necesidades educativas.<br/><br/>
      Para los estudiantes que presentan trastornos específicos del aprendizaje o del comportamiento, se enfatizó la necesidad de contar con personal especializado que pueda ofrecer apoyo adecuado y mejorar la articulación con el sector salud para acelerar diagnósticos y tratamientos. También se abordaron las dificultades en la implementación del Diseño Universal para el Aprendizaje (DUA), una estrategia clave pero limitada por la falta de recursos y la necesidad de formación específica en este enfoque.<br/><br/>
      En el caso de los estudiantes con condiciones de salud que requieren apoyo académico especial, se destacó la urgencia de realizar adaptaciones curriculares personalizadas y de brindar apoyo emocional que tenga en cuenta las repercusiones de su situación en su vida escolar. Se discutió la limitada oferta de aulas hospitalarias y la necesidad de fortalecer estrategias integrales que incluyan la participación activa de la familia, la comunidad y las instituciones educativas.<br/><br/>
      En todos los casos, se subrayó el compromiso de avanzar hacia una educación más equitativa e inclusiva. Esto requiere la colaboración de todos los actores educativos, el fortalecimiento de la formación docente y la mejora de los recursos disponibles, garantizando así el derecho a una educación de calidad para cada estudiante, independientemente de sus circunstancias.
      </>,
      autor: "Por Ana María Ángel Piedrahita",
      orientation: ""
    },
  ];

  const testimonios_voces_familias = [
    { title: "NUESTRA ESCUELA",
      video: "https://drive.google.com/file/d/1Vr9_IpNOidZ3O6Wk64eZA-1wHSaZWi92/preview",
      content: <>Estudiantes y familiares nos muestran cómo sería su institución educativa ideal. Aquí presentamos una de las reflexiones, la cual nos presenta un llamado a pensar de manera profunda sobre cómo concebimos la educación y el papel que tienen los establecimientos educativos en el desarrollo integral de los niños, niñas, adolescentes, jóvenes y adultos. Sus palabras revelan una visión de la escuela como un espacio donde la diversidad es celebrada, donde cada niño se siente valorado y donde el aprendizaje va más allá de los libros y los cuadernos.<br/><br/>
      Al afirmar que "toda la sociedad comience a entender y a comprender las diferentes capacidades que tienen nuestros hijos", se pone en manifiesto la necesidad de una mayor sensibilización y comprensión hacia las diferencias y las particularidades. Cada estudiante es único y aprende de manera distinta, y es fundamental que las instituciones educativas reconozcan y valoren esta diversidad.<br/><br/>
      Su propuesta de una escuela donde "todos nos podamos comprender, entendernos y así mismo podernos ayudar" es un reflejo de una práctica centrada en el estudiante, donde las relaciones interpersonales y la colaboración juegan un papel fundamental. En este tipo de escuela, los niños aprenden no sólo conocimientos académicos, sino también habilidades sociales y emocionales que les permitirán desenvolverse con mayor autonomía en la vida.<br/><br/>
      La idea de crear un espacio donde "se cuenta con zonas recreativas" y donde se fomenta la conexión con la naturaleza, como el río y la granja, es una muestra de cómo la escuela puede convertirse en un entorno enriquecedor y estimulante para el aprendizaje. Al experimentar con la naturaleza, los estudiantes desarrollan su curiosidad, su creatividad y la relación entre lo teórico y lo vivencial.<br/><br/>
      El deseo de Dominic y su gusto por tener un ambiente natural enriquecedor en la escuela es un ejemplo de cómo los sueños de los niños pueden ser una fuente de inspiración para crear experiencias de aprendizaje significativas. Al fomentar la imaginación y la creatividad de los estudiantes, estamos ayudando a construir un futuro en el cual se escuche y se motive a toda la comunidad educativa.<br/><br/>
      Todos los niños, independientemente de sus capacidades o habilidades, tienen el derecho a una educación de calidad. La diversidad es una riqueza y debe ser celebrada en la escuela, brindando un lugar donde todos se sientan motivados a aprender y en donde la conexión con la naturaleza sea fundamental para el desarrollo integral.
      Los sueños de los niños son una fuente de inspiración y deben ser fomentados en todos los niveles de la educación. 
      </>,
      autor: "",
      orientation: "horizontal"
    },
    { title: "ENCUENTRO REGIONAL DE FAMILIAS",
      video: "https://drive.google.com/file/d/1cQaZ3-GVXtFzylZDlge5uMbFtw49g921/preview",
      content: <>A propósito del encuentro regional de familias, en el marco del Convenio UNAL - MEN, para la Equidad y la Inclusión,  una madre de familia que hace la exposición de información desarrolla un discurso donde hace una reflexión importante en torno a la educación inclusiva desde su conocimiento y realidad inmediata; reconociendo además la grata posibilidad de ser partícipe de este tipo de espacios, que permiten no sólo adquirir conocimientos, sino también compartir experiencias, expectativas, intereses, información que termina siendo la excusa de un diálogo en común. <br/><br/>
      Del  mismo modo se favorece en este tipo de acercamientos, dada la posibilidad de empoderar a los actores - en este caso familias - para que se permita la creación de conceptos, tanto como la consolidación de redes de apoyo a fin de replicar la información que se viene concertando y construyendo en estas mesas de diálogo. Este intercambio, les ha permitido hacer una reflexión de cara al proceso de la educación inclusiva en la región, su responsabilidad en el mismo y la proyección de acciones para que se dé una inclusión educativa consolidada. Reconociéndose de forma explícita la necesidad de hacer dicha reflexión en conjunto; a las familias les llena de esperanza saber que este tipo de espacios de diálogo ha sido pensados y trabajados con todos los actores involucrados en el proceso, como son: los funcionarios de las secretarias de educación  municipal, los directivos docentes, docentes de aula, docentes de apoyo, profesionales de apoyo etc; consideran que son ellos quienes deben liderar estos espacios en el territorio, e ir apoyando la transformación de las políticas en prácticas y culturas, siendo los docentes en aula quiénes favorezcan el intercambio de saberes, la identificación y mitigación de barreras, la promoción de espacios de concertación, el favorecimiento del respeto por la diferencia, la valoración de la diversidad, la disminución de la discriminación, de tal forma que asumen un rol importante en el proceso, ya que se convierten de esta manera en facilitadores y garantes de derechos, en pro de una inclusión real. <br/><br/>
      De otro lado, estas familias resaltan la importancia de fortalecer las prácticas inclusivas mediante el uso de la tecnología, cómo medio para la consolidación de los procesos de enseñanza- aprendizaje, acudiendo al argumento que las Instituciones educativas con esos recursos tan valiosos, deben hacer uso de esta como estrategia, pero no como la única estrategia de trabajo y menos pensando en que el trabajo con población de estudiantes con discapacidad, con TDAH Y / o del comportamiento o con habilidades excepcionales, debe darse de manera remota desde el espacio seguro del hogar cómo primera opción de formación académica. Acude cómo madre de familia, al llamado de desarrollar acciones que incluyan la combinación y diversificación de estrategias en el aula, que se convierta así la tecnología en una herramienta para la facilitación, la creatividad, la equidad y el disfrute de la educación en el aula. Es así como se hace preponderante, a partir de la concertación y los diálogos para el logro de una comunidad educativa más incluyente, reconocedora, respetuosa y protectora de los estudiantes en los entornos inclusivos. <br/><br/>
      También, se hace necesario atender a las necesidades y el reconocimiento de las características particulares de las y los estudiantes en el aula, siendo todos los actores del proceso los llamados a asumir los retos y el compromiso ético para dar el valor necesario a los estudiantes en el entorno educativo. Se resalta la manera cómo en muchas Instituciones Educativas, los docentes desde su acción juciosa, creativa  e innovadora, propenden por que los estudiantes desarrollen al máximo sus potencialidades e involucran de manera directa a la familia, su rol fundamental, e insustituible para el desarrollo de los miembros de una familia, reconociendo  además que la forma de poder desarrollar un proceso inclusivo, tiene cómo base el trabajo y la responsabilidad de los actores de la comunidad educativa interna y externa.
      </>,
      autor: "Por Olga Cristina Rodríguez",
      orientation: "horizontal"
    },
  ];

  const testimonios_voces_estudiantes = [
    { title: "LLEVAR EL LEGADO A LA EDUCACIÓN SUPERIOR",
      video: "https://drive.google.com/file/d/17zW3Wr8E_SUQk_XA34IsbuRVAH8XuMLx/preview",
      content: <>¡Gran noticia! Sebastián, un estudiante de Nariño, se presentó para estudiar Medicina en la Universidad Nacional de Colombia sede Bogotá y pasó el examen de admisión, será de gran orgullo para su territorio.<br/><br/>
      La sociedad contemporánea, marcada por un ritmo acelerado y una creciente individualización, nos llama a reflexionar sobre la importancia de reconectar con nuestros semejantes. En este contexto, la vocación de servir y la capacidad de empatizar se contemplan como pilares fundamentales para construir comunidades más justas y solidarias.<br/><br/>
      El deseo de Sebastián de contribuir a un mundo mejor lo ha llevado a explorar diferentes caminos, y uno de ellos ha sido la decisión de estudiar Medicina. Esta carrera, más allá de ser una formación académica, representa un compromiso profundo con la salud y el bienestar de los demás. La posibilidad de tratar el dolor y la incomodidad, de brindar esperanza y de acompañar a las personas en los momentos más vulnerables de sus vidas es un desafío que desea asumir con gran responsabilidad.<br/><br/>
      Sebastián nos menciona que la vocación de servir no se limita al ámbito médico. La psicología, por ejemplo, ofrece otra vía para conectar con las personas a un nivel más profundo, explorando sus emociones, pensamientos y comportamientos. Tanto en la medicina como en la psicología, el profesional no es solo un espectador, sino un actor activo en la vida de sus pacientes. Su capacidad para escuchar, comprender y acompañar marcará la diferencia en el proceso.<br/><br/>
      Es importante destacar que la relación entre el profesional de la salud y la persona que busca ayuda debe ir más allá de lo estrictamente técnico. La empatía, la calidez humana y la capacidad de establecer un vínculo de confianza son elementos esenciales para generar un impacto positivo en la vida de los demás. Cuando escuchamos a alguien con atención, cuando nos ponemos en su lugar y cuando le brindamos nuestro apoyo incondicional, estamos sembrando las semillas del cambio.<br/><br/>
      Este cambio no se limita al individuo, sino que se extiende a toda la comunidad. Al fomentar relaciones basadas en la empatía y el respeto, contribuimos a crear un entorno más saludable y cohesionado. La violencia, la discriminación y la exclusión son, en gran medida, el resultado de la falta de empatía y comprensión hacia los demás.<br/><br/>
      Sebastián nos invita a todos a cultivar la capacidad de ponerse en el lugar del otro. La empatía debe constituirse como una habilidad que se puede desarrollar a través de la práctica y la reflexión. Al hacerlo, no sólo estaremos ayudando a los demás, sino que también estaremos enriqueciendo nuestra propia vida.<br/><br/>
      La vocación de servir es un llamado universal. Cada uno de nosotros, desde nuestro propio lugar, podemos contribuir a hacer del mundo un lugar mejor. Ya sea a través de la medicina, la psicología, el voluntariado, la docencia, el cuidado o cualquier otra actividad, nuestras acciones pueden marcar la diferencia.<br/><br/>
      Felicitaciones para Sebastián, sabemos que a partir de las experiencias enriquecedoras que vivió en su paso por su institución educativa puede marcar una pauta de respeto en su contexto universitario. 
      </>,
      autor: "",
      orientation: "horizontal"
    },
    { title: "SENTIRES DE LOS ESTUDIANTES",
      video: "https://drive.google.com/file/d/1JJ27gA7vPeptEN8OeFiSGaPM5w9NeGMZ/preview",
      content: <>Los estudiantes de Nariño y Putumayo nos permiten conocer aquello que más les gusta y los motiva a partir de la percepción de sus instituciones educativas. Al expresar que lo que más les interesa de su colegio es "la libertad de expresión y poderse expresar como quiera", se pone de manifiesto la importancia de crear espacios educativos donde los estudiantes se sientan valorados y escuchados. Esta libertad es fundamental para el desarrollo de la autoestima, la autonomía y la capacidad de comunicarse de manera efectiva.<br/><br/>
      La mención del juego, el recreo y la interacción con los amigos nos revela la dimensión social del aprendizaje. La escuela no es solo un lugar para adquirir conocimientos, sino también un espacio para construir relaciones, desarrollar habilidades sociales y emocionales, y formar parte de una comunidad. Al jugar y compartir con compañeros, se aprende a cooperar, a resolver conflictos, a empatizar y a construir vínculos afectivos que perdurarán a lo largo de la vida.<br/><br/>
      Algunos espacios mencionados como la biblioteca, viéndola desde un lugar de conocimiento y descubrimiento, juega un papel fundamental en la experiencia escolar. Al igual que otros espacios de aprendizaje los cuales amplían sus horizontes y contribuyen a la construcción de identidad.<br/><br/>
      La afirmación "todo", refiriéndose a un gusto generalizado sintetiza de manera muy acertada la experiencia escolar. La escuela es un lugar donde los niños, niñas, adolescentes y jóvenes crecen en todos los sentidos: cognitivo, social, emocional y físico. A través de las interacciones con tus profesores y compañeros, y se desarrollan habilidades y conocimientos que serán útiles a lo largo de toda su vida.<br/><br/>
      <b>Podemos destacar:</b>
      <ul>
        <li><b>La importancia de las relaciones interpersonales: </b>las relaciones que se establecen con profesores y compañeros tienen un impacto significativo en el aprendizaje y en el bienestar emocional. Un ambiente de aprendizaje acogedor fomenta la motivación y el compromiso.</li>
        <li><b>El valor de la diversidad: </b>la escuela es una fracción de la sociedad. Al interactuar con personas de diferentes orígenes, culturas y habilidades se aprende a valorar la diversidad y a construir una sociedad más justa y equitativa.</li>
        <li><b>La relevancia de las experiencias personales: </b>las experiencias que se viven en la escuela, tanto dentro como fuera del aula, contribuyen a formar el ser de una manera integral.</li>
      </ul>
      Los sentires y pensares de los estudiantes nos brinda una invitación a repensar los establecimientos educativos como un lugar donde los estudiantes no solo adquieren conocimientos, sino también desarrollan habilidades sociales, emocionales y comunicativas. Al escuchar la voz de los estudiantes, podemos construir una educación con equidad y calidad.
      </>,
      autor: "",
      orientation: "horizontal"
    },
    { title: "LA EDUCACIÓN INCLUSIVA: PROCESO CONTINUO DE TRANSFORMACIÓN CULTURAL, DE PRÁCTICAS Y POLÍTICAS",
      video: "https://drive.google.com/file/d/10ZQBMj-l3x6PZeysYJrOciMhnKto-okn/preview",
      content: <>Durante el encuentro regional de familias y estudiantes del Eje cafetero, se destacó la participación de Daniel Felipe, perteneciente a la institución educativa INEM del departamento de Caldas. En el video en mención, expresa varios aspectos fundamentales relacionados con la educación inclusiva que son de gran importancia no solamente para la discapacidad sino para talentos excepcyionales, condición de enfermedad y trastornos del aprendizaje/ comportamiento. Ya que en su discurso menciona la importancia de la educación desde los primeros años de vida, no solamente para acceder al conocimiento, si no para socializar y ser parte de una comunidad. De igual forma destaca aspectos cruciales, como el fortalecimiento de prácticas educativas innovadoras para hacer frente a los diversos desafíos que enfrentan los estudiantes al inicio de la educación escolar. <br/><br/>
      Sumado a esto muchos estudiantes que requieren algún ajuste razonable inician su trayectoria educativa, en palabras de Daniel Felipe con "dificultades y deficiencias"; sin embargo, con el apoyo de los docentes y familia se puede alcanzar una educación de calidad, logrando la culminación del bachillerato, posteriormente el ingreso a la educación superior. Las instituciones educativas deben promover en todos los estudiantes como sujetos con plenas capacidades<br/><br/>
      Otro elemento a destacar son los imaginarios culturales que se tiene sobre la discapacidad y los otros énfasis como talentos excepcionales, trastornos del aprendizaje y los estudiantes en condición de enfermedad; al pensar que no es posible el acceso a la educación, el estudiante reconoce que esta transformación cultural depende no depende únicamente del individuo, sino que es un proceso colaborativo que requiere diferentes apoyos como el de la familia, las instituciones educativas, entidades territoriales y la comunidad para generar condiciones que promuevan la independencia y la participación dentro de la sociedad. <br/><br/>
      Su discurso fundamental se centra en demostrar que las dificultades no son limitaciones permanentes, sino obstáculos que pueden superarse mediante una educación inclusiva, de calidad, innovadora y comprometida que permita desarrollar todo el potencial de los estudiantes.  Se debe reconocer que la inclusión, va más allá de la integración dentro de un modelo educativo, sino que reconocer la diversidad como una fortaleza, que adapta sus metodologías para garantizar que cada estudiante pueda desarrollarse plenamente. <br/><br/>
      Por lo tanto, la equidad en educación se materializa cuando se propone modelos educativos flexibles que respondan a las necesidades específicas de cada estudiante. No se trata de nivelar hacia abajo, sino de crear estrategias que permitan a cada individuo alcanzar su máximo potencial. La innovación educativa, desde esta perspectiva, implica reimaginar constantemente las prácticas pedagógicas para garantizar una verdadera inclusión.<br/><br/>
      En síntesis, a partir de lo expresado por el estudiante es esperanzador y retador, en el sentido que se demuestra los avances que ha tenido el país en el marco de la inclusión y equidad educativa; ya que se evidencia una historia de vida, donde el estudiante es parte y líder de su comunidad educativa. La reflexión nos conduce inevitablemente a comprender que la inclusión educativa no es un objetivo alcanzado, sino un proceso continuo de transformación cultural, de prácticas y políticas Cada barrera puede ser superada cuando existe un compromiso real con la educación como derecho fundamental y que es responsabilidad de todos promoverla. 
      </>,
      autor: "Por Pilar Taramuel",
      orientation: "vertical"
    },
    { title: "NOTA REFLEXIVA SOBRE EL VIDEO DE UN NIÑO CON TALENTOS EXCEPCIONALES",
      video: "https://drive.google.com/file/d/128DSZNwyliyaqfNO1nGtk05NIA7Fh-YK/preview",
      content: <>En el video se muestra a un estudiante con altas capacidades dando cuenta de su visión de una institución que promueve la educación inclusiva. Este estudiante, como es frecuente en la población con talentos excepcionales, organiza la información en categorías que son tratadas de manera sistemática. No obstante, contrario a lo que suele suponerse, la mente de estos niños y niñas no plantea necesariamente un discurso completo totalmente estructurado, sin errores, sin dudas, sin matices. Muy al contrario, los diálogos de las niñas y niños con talentos excepcionales están enmarcados en sus propias ansiedades, en su profunda búsqueda interna de lograr adaptarse a entornos que generalmente le son ajenos, que van a otros ritmos, en otras sintonías, en caminos que aparentemente no coinciden con su historia y con sus impulsos internos. <br/><br/>
      También son discursos que están inmersos en sus temores, en las frustraciones y soledades que suelen acompañar sus días, en sus necesidades de encajar, ser aceptados y comprender su propia identidad, en medio de patrones culturales y estructuras políticas que los desconocen y que no los tienen en cuenta como ciudadanos con necesidades particulares, que requieren ser escuchados, comprendidos e incluidos de manera integral y propositiva en la dinámica social, como actores representativos de la diversidad.<br/><br/>
      Como se observa en el video, si se revisa con detenimiento, en la población con talentos excepcionales las ideas corrientemente se atraviesan unas a otras como un torrente que requiere ser calmado para encontrar un curso vital que les permita fluir. En este maremágnum de ideas los oyentes con frecuencia se quedan con una sensación de incomprensión, de lenguaje taquigráfico, sin que ninguna de las dos partes de la conversación tenga las claves que faciliten la comunicación profunda.<br/><br/>
      No obstante, nuestro estudiante ha sido parte de procesos de inclusión que le permiten canalizar su pensamiento y ordenarlo en función de su objetivo comunicativo; logra sintonizar su ritmo, pensamiento y lenguaje con el de los otros para traducir sus ideas y argumentos a un lenguaje común.<br/><br/>
      Un apartado de su discurso llama la atención, cuando se piensa en la educación inclusiva. “Tenemos que ser iguales a los profesores”, afirma el estudiante, refiriéndose a la sensibilidad y al principio de equidad. Iguales en factores como la raza y el género, ejemplifica, pero también en términos del aporte y del comportamiento en los espacios de aprendizaje. Específicamente, el estudiante hace alusión al respeto, a los acuerdos y la comprensión del otro a guisa de elementos esenciales para reconocer la diferencia como un valor que aporta otros puntos de vista para enriquecer la cultura y promover el desarrollo, lo que ha sido resaltado como punto de partida en todas las teorías constructivistas del aprendizaje.<br/><br/>
      De otro lado, ese respeto se esboza en la escucha atenta y completa al otro, en la apertura mental necesaria para reconocerse en ese otro, con el derrotero de la equidad y la justicia social en calidad de principios para aprender a convivir y construir un mundo sustentable.<br/><br/>
      Por fuertes, autónomos, independientes y autorregulados que parezcan los y las niñas con talentos excepcionales, son absolutamente frágiles en un mundo que no ha sido creado para ellos. Requieren de nuestra escucha, comprensión y reconocimiento para insertarlos de manera activa y productiva en la estructura social.
      </>,
      autor: "",
      orientation: "vertical"
    },
  ];


  return (
    <>

      {/* Banner section transformación desde el aula */}
      {section === "transformacion" &&
        <Grid container className='Transformacion-banner-container'>
          <Grid item xs={12} lg={6} className='logos-left'>
            <img className="logo-1" alt="Logo Ministerio de educación" src={logo_educacion} />
            <div className='right-border'></div>
            <img className="logo-2" alt="Logo Universidad Nacional de Colombia" src={logo_unal} />
          </Grid>
          <Grid item xs={12} lg={6} className='content-right'>
            <h1 className='title'><b>Transformación desde el aula</b></h1>
          </Grid>
        </Grid>
      }

      {/* Banner section voces y familias */}
      {(section === "familias" || section === "estudiantes") &&
        <Grid container className='Voces-banner-container'>
          <Grid item xs={12} lg={4} className='logos-left'>
            <img className="logo-1" alt="Logo Ministerio de educación" src={logo_educacion} />
            <div className='right-border'></div>
            <img className="logo-2" alt="Logo Universidad Nacional de Colombia" src={logo_unal} />
          </Grid>
          <Grid item xs={12} lg={8} className='content-right'>
            <h1 className='title'><b>Voces de las familias y estudiantes</b></h1>
          </Grid>
        </Grid>
      }

      {/* Videos testimoniales */}
      <Grid container className='videos-testimoniales-container backContraste'>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11}>
            <Typography className='title'>
              Videos testimoniales
            </Typography>
          </Grid>
        </Grid>
        {section === "transformacion" &&
          <>
          <Grid item xs={12} lg={9} className='testimonio-container-info'>
            <Grid item xs={12} className='text-container-2'>
              <Grid xs={11}>
                <Typography className='title'>
                  {testimonios_transformacion[Number(id) - 1].title}
                </Typography>
              </Grid>
            </Grid>
            { testimonios_transformacion[Number(id) - 1].video &&
              <Grid container item xs={12} className='video-container'>
                <iframe src={testimonios_transformacion[Number(id) - 1].video} className={`video-item ${testimonios_transformacion[Number(id) - 1].orientation}`} allowFullScreen>
                </iframe>
              </Grid>
            }
            <Grid item xs={12} className='text-container-2'>
              <Grid xs={11}>
                <Typography className='txt'>
                  {testimonios_transformacion[Number(id) - 1].content}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} className='text-container-2'>
              <Grid xs={11}>
                <Typography className='autor'>
                  {testimonios_transformacion[Number(id) - 1].autor}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} lg={3} className='cards-testimonios-container'>
              <Grid item xs={12} className='card-testimonios-space'>
                <CardTestimonios
                  title="Transformando la educación a través de la participación activa en Arauca: Entrevista con Lourdes Medina"
                  img={testimony1}
                  imgDesc="Imagen testimonio"
                  autor="Por: Alejandro García Gómez"
                  testimonio={1}
                  type="transformacion"
                  pagina="transformacion-desde-el-aula"
                />
              </Grid>
              <Grid item xs={12} className='card-testimonios-space'>
                <CardTestimonios
                  title="Desafíos y Estrategias para Superar Barreras en Grupos Vulnerables de Aguachica, Cesar"
                  img={directivos}
                  imgDesc="Imagen testimonio"
                  autor="Por: Mónica Buelvas"
                  testimonio={2}
                  type="transformacion"
                  pagina="transformacion-desde-el-aula"
                />
              </Grid>
              <Grid item xs={12} className='card-testimonios-space'>
                <CardTestimonios
                  title="Educación Inclusiva: Una Mirada al Pasado, Presente y Futuro desde el Eje Cafetero"
                  img={testimony3}
                  imgDesc="Imagen testimonio"
                  autor="Por: Olga Lucía López"
                  testimonio={3}
                  type="transformacion"
                  pagina="transformacion-desde-el-aula"
                />
              </Grid>
            </Grid>
          </>
        }
        {section === "familias" &&
          <>
            <Grid item xs={12} lg={9} className='testimonio-container-info backContraste'>
              <Grid item xs={12} className='text-container-2'>
                <Grid xs={11}>
                  <Typography className='title'>
                    {testimonios_voces_familias[Number(id) - 1].title}
                  </Typography>
                </Grid>
              </Grid>
              { testimonios_voces_familias[Number(id) - 1].video &&
                <Grid container item xs={12} className='video-container'>
                  <iframe src={testimonios_voces_familias[Number(id) - 1].video} className={`video-item ${testimonios_voces_familias[Number(id) - 1].orientation}`} allowFullScreen>
                  </iframe>
                </Grid>
              }
              <Grid item xs={12} className='text-container-2'>
                <Grid xs={11}>
                  <Typography className='txt'>
                    {testimonios_voces_familias[Number(id) - 1].content}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container-2'>
                <Grid xs={11}>
                  <Typography className='autor'>
                    {testimonios_voces_familias[Number(id) - 1].autor}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} lg={3} className='cards-testimonios-container'>
              <Grid item xs={12} className='card-testimonios-space'>
                <CardTestimonios
                  title="NUESTRA ESCUELA"
                  img={testimony1F}
                  imgDesc="Imagen testimonio"
                  autor=""
                  testimonio={1}
                  type="familias"
                  pagina="voces-familias-y-estudiantes"
                />
              </Grid>
              <Grid item xs={12} className='card-testimonios-space'>
                <CardTestimonios
                  title="ENCUENTRO REGIONAL DE FAMILIAS"
                  img={testimony2F}
                  imgDesc="Imagen testimonio"
                  autor="Por Olga Cristina Rodríguez"
                  testimonio={2}
                  type="familias"
                  pagina="voces-familias-y-estudiantes"
                />
              </Grid>
            </Grid>
          </>
        }
        {section === "estudiantes" &&
          <>
            <Grid item xs={12} lg={9} className='testimonio-container-info backContraste'>
              <Grid item xs={12} className='text-container-2'>
                <Grid xs={11}>
                  <Typography className='title'>
                    {testimonios_voces_estudiantes[Number(id) - 1].title}
                  </Typography>
                </Grid>
              </Grid>
              { testimonios_voces_estudiantes[Number(id) - 1].video &&
                <Grid container item xs={12} className='video-container'>
                  <iframe src={testimonios_voces_estudiantes[Number(id) - 1].video} className={`video-item ${testimonios_voces_estudiantes[Number(id) - 1].orientation}`} allowFullScreen>
                  </iframe>
                </Grid>
              }
              <Grid item xs={12} className='text-container-2'>
                <Grid xs={11}>
                  <Typography className='txt'>
                    {testimonios_voces_estudiantes[Number(id) - 1].content}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container-2'>
                <Grid xs={11}>
                  <Typography className='autor'>
                    {testimonios_voces_estudiantes[Number(id) - 1].autor}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          <Grid container xs={12} lg={3} className='cards-testimonios-container'>
            <Grid item xs={12} className='card-testimonios-space'>
              <CardTestimonios
                title="LLEVAR EL LEGADO A LA EDUCACIÓN SUPERIOR"
                img={testimony1E}
                imgDesc="Imagen testimonio"
                autor=""
                testimonio={1}
                type="estudiantes"
                pagina="voces-familias-y-estudiantes"
              />
            </Grid>
            <Grid item xs={12} className='card-testimonios-space'>
              <CardTestimonios
                title="SENTIRES DE LOS ESTUDIANTES "
                img={testimony2E}
                imgDesc="Imagen testimonio"
                autor=""
                testimonio={2}
                type="estudiantes"
                pagina="voces-familias-y-estudiantes"
              />
            </Grid>
            <Grid item xs={12} className='card-testimonios-space'>
              <CardTestimonios
                title="LA EDUCACIÓN INCLUSIVA: PROCESO CONTINUO DE TRANSFORMACIÓN CULTURAL, DE PRÁCTICAS Y POLÍTICAS."
                img={testimony3E}
                imgDesc="Imagen testimonio"
                autor="Por Pilar Taramuel"
                testimonio={3}
                type="estudiantes"
                pagina="voces-familias-y-estudiantes"
              />
            </Grid>
          </Grid>
        </>
        }
      </Grid>
    </>
  );
};