import './TransformacionSection.scss';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import logo_educacion from '../../../assets/images/logo_educacion.png';
import classroom from '../../../assets/images/classroom.png';
import directivos from '../../../assets/images/img_directivos.webp';
import manifiestodi from '../../../assets/documents/Pieza1_C3_INF01_02 PDF.pdf';
import manifiestodo from '../../../assets/documents/manifiesto_docentes_ap.pdf';
import testimony1 from '../../../assets/images/testimony_1.webp';
import testimony3 from '../../../assets/images/testimony_3.webp';
import testimony4 from '../../../assets/images/testimony_4.webp';
import testimony5 from '../../../assets/images/testimony_5.webp';
import testimony6 from '../../../assets/images/testimony_6.webp';
import { Dialog, DialogContent, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

const CardDirectivos = ({ title, text, fullText, video }: any) => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <>  
          <Grid container className='card-directivos-container' onClick={() => handleOpen()}>
            <Grid container item xs={12} className='title-container'>
              <Typography className='title'>
                {title}
              </Typography>
            </Grid>
            <Grid container item xs={12} className='img-item'>
              <iframe src={video} className='info-img'>
              </iframe>
            </Grid>
            <Grid container item xs={12} className='text-container'>
              <Typography className='text'>
                {text}
              </Typography>
            </Grid>
          </Grid>
          <Dialog open={open} onClose={handleClose} maxWidth='lg' className='modal-directivos'>
            <DialogContent style={{ padding: 20 }}>
              <Grid container item xs={12} className='text-container'>
                <Typography className='title'>
                  {title}
                </Typography>
              </Grid>
              <Grid container item xs={12} className='video-container'>
                <iframe src={video} className='video-item' allowFullScreen>
                </iframe>
              </Grid>
              <Grid container item xs={12} className='text-container'>
                <Typography className='text-info'>
                  {fullText}
                </Typography>
              </Grid>
            </DialogContent>
        </Dialog>
      </>
  );
}

const CardDocentes = ({ title, img, imgDesc, autor, action, testimonio }: any) => {

  return (
      <>  
          <Grid container className='card-docentes-container' onClick={action}>
            <Grid container item xs={12} className='img-item'>
              <img src={img} alt={imgDesc} className='info-img' />
            </Grid>
            <Grid container item xs={12} className='title-container'>
              <Typography className='title'>
                {title}
              </Typography>
            </Grid>
            <Grid container item xs={12} className='text-container'>
              <Typography className='text'>
                {autor}
              </Typography>
            </Grid>
            <div className='button-container'>
              <Link to={`/transformacion-desde-el-aula/testimonios/${testimonio}?query=transformacion`}>
                <Button variant="contained" fullWidth className='testimony-button'>
                    <Typography className='btn-txt'>
                      Ver testimonio
                    </Typography>
                </Button>
              </Link>
            </div>
          </Grid>
      </>
  );
}

export const TransformacionSection = () => {

  const [open2, setOpen2] = useState(false);

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [openItem, setOpenItem] = useState(0);

  const toggleItem = (itemIndex: any) => {
    setOpenItem(itemIndex);
  };

  useEffect(() => {
    const items = document.querySelectorAll('[id^="item-"]');
    
    items.forEach((item) => {
      item.classList.remove('active-item');
    });

    const activeItem = document.getElementById(`item-${openItem}`);
    if (activeItem) {
      activeItem.classList.add('active-item');
    }
  }, [openItem]);

  const directivos_info = [
    { title: "Amazonas",
      description: "La escuela ha enfrentado el desafío de educar a estos estudiantes sin contar...",
      fullDescription: "La escuela ha enfrentado el desafío de educar a estos estudiantes sin contar con intérpretes o modelos lingüísticos adecuados. Los docentes, sin experiencia previa en lengua de señas, han desarrollado estrategias para avanzar en su aprendizaje, utilizando herramientas visuales como videos, imágenes y escritura. La profesora Yaneth Patricia Camargo, de lengua castellana, menciona la dificultad inicial de comunicación, pero destaca el vínculo cercano que han construido con el tiempo. Ronald Hilo, docente de matemáticas, ha implementado actividades vinculadas al deporte, aprovechando el interés de Fabio y Edward, como ejercicios matemáticos prácticos y visuales. Los docentes se han capacitado en Lengua de señas y se ha asesorado con el INSOR.",
      video: "https://drive.google.com/file/d/1LRvJpZyiDvdh3X5GDZ9PL2DoYuXfKNt8/preview"
    },
    { title: "San Andrés",
      description: 'El docente encargado, Alfonso Peterson, ha utilizado estas experiencias...',
      fullDescription: 'El docente encargado, Alfonso Peterson, ha utilizado estas experiencias como un incentivo para especializarse en educación inclusiva. Actualmente, cursa un diplomado en la Unidad de Atención a la Inclusión (UAI) y ha desarrollado prácticas pedagógicas innovadoras para responder a la diversidad en el aula. Además, fomenta el respeto por la diversidad y promueve la inclusión activa de Jeisen en eventos escolares y culturales, como izadas de bandera y celebraciones de la afrocolombianidad.',
      video: "https://drive.google.com/file/d/1qBfdK1Qc6f2i2qfBRyWhPxT8II-x9bWV/preview"
    },
    { title: "Eje cafetero",
      description: "La Institución Educativa Santa Sofía ha sido un pilar en la transformación...",
      fullDescription: 'La Institución Educativa Santa Sofía ha sido un pilar en la transformación educativa y social de Brayan. Desde su ingreso hace cuatro años. Brayan pasó rápidamente de no saber leer ni escribir a avanzar en sus estudios y ser promovido de manera inmediata a grados superiores. El profesor Fredy destaca la importancia de reconocer que cada persona aprende de manera diferente, enfatizando la necesidad de implementar una flexibilización curricular y utilizar andamiajes que faciliten el aprendizaje. Su consejo para los educadores es: "Imagínese cómo sería estar en el lugar de ese estudiante".',
      video: "https://drive.google.com/file/d/1XU2O1VnmpoMfQNAryXp_NPcVSUOShTvk/preview"
    },
    { title: "Antioquia",
      description: "La docente a cargo, Gloria Roldán, junto con la docente de apoyo...",
      fullDescription: "La docente a cargo, Gloria Roldán, junto con la docente de apoyo Claudia Graciano, y la orientadora Margarita  han implementado estrategias basadas en el Diseño Universal para el Aprendizaje (DUA) para promover un entorno inclusivo. Resaltan la importancia del apoyo familiar.",
      video: "https://drive.google.com/file/d/1btHCKmczYZqJJ59OFiiB3QWYRKW6AjsT/preview"
    },
    { title: "Santander",
      description: "La institución ha realizado un esfuerzo significativo por superar...",
      fullDescription: "La institución ha realizado un esfuerzo significativo por superar barreras metodológicas y logísticas, implementando estrategias pedagógicas flexibles y utilizando recursos audiovisuales para facilitar el aprendizaje. El profesor Óscar Javier reflexiona sobre los estigmas que tenía inicialmente para educar a Cristian y destaca los cambios positivos que ha observado, especialmente en su autonomía y la comunicación con sus pares y docentes.",
      video: "https://drive.google.com/file/d/1MRQztkm7oMySWR3EXxe8DgWLZpkTwnoc/preview"
    },
    { title: "Caribe",
      description: "La docente Leidy resalta la importancia de garantizar el derecho...",
      fullDescription: "La docente Leidy resalta la importancia de garantizar el derecho a la educación para todas las personas hospitalizadas. Las aulas hospitalarias (AH) brindan un espacio en el que los niños y niñas pueden olvidar temporalmente su enfermedad y hospitalización. A través de estas estrategias, se ofrece el programa a las familias, comenzando con la matrícula del estudiante en una institución educativa. Luego, se le proporciona atención en el hospital, y cuando el niño o niña egresa, se realiza un tránsito armónico para su reintegro a la institución.",
      video: "https://drive.google.com/file/d/1eyDw06rt-TYzKnaqr_EcgnaHvgPTSlh2/preview"
    },
    { title: "Valle del cauca",
      description: "La IEO, comprometida con su desarrollo, trabajó para adaptar su proceso...",
      fullDescription: "La IEO, comprometida con su desarrollo, trabajó para adaptar su proceso de aprendizaje mediante la creación de un Plan Individual de Ajustes Razonables (PIAR). Este plan flexibilizó el currículo y permitió que Juan Felipe continuará su formación con diferentes  estrategias con el apoyo de docentes dedicados y la colaboración de la Secretaría de Educación, se implementaron medidas como la promoción anticipada para evitar la deserción escolar y asegurar que pudiera culminar sus estudios.",
      video: "https://drive.google.com/file/d/1Ofj_2t9G0s_Q3VkHNFsB5wq1bpDivztF/preview"
    },
    { title: "Arauca",
      description: "Debido a su excelente rendimiento y disciplina, el Instituto Departamental...",
      fullDescription: "Debido a su excelente rendimiento y disciplina, el Instituto Departamental del Deporte y la Recreación (INDER) de Arauca solicitó a su cuidadora su inmediata vinculación al sistema educativo, con el objetivo de permitirle culminar una etapa importante de su vida.",
      video: "https://drive.google.com/file/d/1ZU2jhMGwVKdkvnlZSvMST7nLBPap0yZJ/preview"
    },
    { title: "Tumaco",
      description: "Carlos Palacios, docente de educación física, comparte cómo Evelin...",
      fullDescription: "Carlos Palacios, docente de educación física, comparte cómo Evelin inició su participación en el programa de escuelas deportivas, destacando el papel fundamental de la disciplina en su progreso. Por su parte, el profesor Frank Castillo, director del grupo de danza, subraya el impacto transformador del arte en las vidas de las personas. De manera similar, Jhon Jairo, director de la Casa Lúdica, resalta la importancia tanto del arte como del deporte como herramientas clave para transformar vidas y generar cambios positivos.",
      video: "https://drive.google.com/file/d/1FRFdNchQXdEcZbIMcbHweHGx6n5wOEGE/preview"
    },
    { title: "Cesar",
      description: "La docente de apoyo Nelvis Cantillo destaca la importancia de implementar...",
      fullDescription: "La docente de apoyo Nelvis Cantillo destaca la importancia de implementar ajustes razonables para estudiantes con discapacidad, talentos y capacidades excepcionales, así como para aquellos en condición de enfermedad. Además, subraya la relevancia de capacitar a los docentes para responder a las necesidades individuales de estos estudiantes. Por su parte, la profesora Luz Marina Galván resalta el valor del PIAR (Plan Individual de Ajustes Razonables) como herramienta para motivar a los estudiantes en su proceso de aprendizaje. También enfatiza la importancia de comprender la forma de comunicación de Alejandra, especialmente a través de su expresión gráfica.",
      video: "https://drive.google.com/file/d/1vlYghEyPC8yCP3iCettDZSeIrWpAhPSU/preview"
    },
    { title: "Andina- Funza",
      description: "La Institución Educativa Técnica Bicentenario ha implementado cambios...",
      fullDescription: "La Institución Educativa Técnica Bicentenario ha implementado cambios significativos en sus prácticas pedagógicas para apoyar la inclusión de estudiantes como Juan Gabriel. Desde el uso de herramientas tecnológicas adaptadas, como el software JAWS, hasta la implementación del Diseño Universal para el Aprendizaje (DUA), la escuela ha creado un entorno accesible y flexible. Los docentes, como Luis Fernando Soacha y Ana Mercedes Téllez, han jugado un papel clave en adaptar el currículo y fomentar la participación de Juan Gabriel en proyectos interdisciplinarios como el semillero de investigación COBIIN.",
      video: "https://drive.google.com/file/d/1MuOvc5eQEeCzAvaHWLXIwgZkyYMcvgWh/preview"
    },
    { title: "Andina- Duitama",
      description: "Erika Gordillo, directora de curso, menciona que desde 2019 el colegio...",
      fullDescription: "Erika Gordillo, directora de curso, menciona que desde 2019 el colegio fue focalizado para abrir la oferta educativa para estudiantes sordos. Por su parte, Yuli, la modelo lingüístico, destaca la importancia de la adquisición de la Lengua de Señas Colombiana (LSC) mediante diversas actividades pedagógicas. El video también resalta la creación de una estrategia innovadora para enseñar ciencias naturales utilizando la LSC a todos los estudiantes.",
      video: "https://drive.google.com/file/d/1WuA82ct-5HjOXlbzK7B6lesrJC11vv7N/preview"
    },
    { title: "Nariño - Pasto",
      description: "Milena Bacca, profesora de español y filosofía, destaca la importancia...",
      fullDescription: 'Milena Bacca, profesora de español y filosofía, destaca la importancia del proceso de alfabetización llevado a cabo con Sebastián. Por otro lado, una docente de apoyo describe la educación inclusiva así:  "La educación inclusiva es el respeto por la diferencia, potenciando capacidades. La educación inclusiva somos todos; que todos reciban del sistema lo que verdaderamente necesitan". También se resalta el papel fundamental de la Secretaría de Educación, que ha proporcionado docentes de apoyo para la institución y ha promovido capacitaciones a través de seminarios y congresos en los que participa el 100% del cuerpo docente de la institución educativa, junto con las familias. La profesora Milena concluye con una frase que refleja la esencia de Sebastián: "Eres un gran lector de la vida".',
      video: "https://drive.google.com/file/d/1sNAfAOE1Kovy7habNKjDbt1_V-e9Hj1z/preview"
    },

  ];

  return (
    <>

      {/* Banner section */}
      <Grid container className='Transformacion-banner-container'>
        <Grid item xs={12} lg={6} className='logos-left'>
          <img className="logo-1" alt="Logo Ministerio de educación" src={logo_educacion} />
        </Grid>
        <Grid item xs={12} lg={6} className='content-right'>
          <h1 className='title'><b>Transformación desde el aula</b></h1>
        </Grid>
      </Grid>

      {/* Description section */}
      <Grid container className='Transformacion-description-container backContraste'>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11}>
            <Typography className='title'>
              Impulsamos la transformación desde el aula, un espacio donde la diversidad se celebra como una riqueza y cada estudiante encuentra oportunidades para aprender, convivir, crecer, alcanzar su máximo potencial y contribuir al bien común.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11} lg={10}>
            <Typography className='text-info'>
              Esta sección está diseñada para que directivos docentes, docentes de aula, docentes orientadores, docentes de apoyo pedagógico, profesionales de apoyo y personal de apoyo promuevan y fortalezcan en sus prácticas educativas la inclusión, equidad, calidad, diversidad, accesibilidad, innovación y compromiso.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Acciones section */}
      <Grid container className='Transformacion-acciones-container backContraste'>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11}>
            <Typography className='title'>
              Acciones por roles educativos
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='sections-container'>
          <Grid xs={12} lg={5}>
            <Typography className='title-2 border-right' onClick={() => toggleItem(0)} id="item-0">
              Directivos docentes, docentes orientadores y docentes de aula
            </Typography>
          </Grid>
          <Grid xs={12} lg={5}>
            <Typography className='title-2' onClick={() => toggleItem(1)} id="item-1">
              Docentes de apoyo pedagógico, profesionales y personal de apoyo
            </Typography>
          </Grid>
        </Grid>

        {/* Directivos */}
        {openItem === 0 &&
          <Grid container className='Directivos-container backContraste'>
            <Grid item xs={12} className='text-container'>
              <Grid xs={11}>
                <Typography className='title'>
                  Historias y videos experienciales
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} className='text-container'>
              <Grid item xs={11} lg={10}>
                <Typography className='text-info'>
                  Explora los videos experienciales que narran cómo directivos docentes, docentes de aula y docentes orientadores han transformado las aulas en espacios donde todos los estudiantes, sin excepción, aprenden, participan y desarrollan su potencial. Cada video, grabado en una región, destaca los logros, desafíos y estrategias que han cambiado vidas y generado aprendizaje significativos gracias a prácticas educativas inclusivas, equitativas y de calidad.
                </Typography>
              </Grid>
            </Grid>
            <Swiper
              modules={[Navigation]}
              navigation
              spaceBetween={60}
              slidesPerView={1}
              breakpoints={{
                1024: { slidesPerView: 4 },
                600: { slidesPerView: 1 },
              }}
              pagination={{ clickable: true }}
              className='directivos-carousel'
            >
              {directivos_info.map((item, index) => (
                <SwiperSlide key={index} className='directivos-carousel-slide'>
                  <CardDirectivos 
                    title={item.title}
                    imgDesc="video experencial"
                    text={item.description}
                    fullText={item.fullDescription}
                    video={item.video}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Grid container justifyContent={'center'} className='Transformacion-manifiesto-container backContraste'>
                <Grid xs={11}>
                  <hr className='separator'/>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Manifiesto nacional por una Educación Inclusiva, Equitativa y de Calidad de los Directivos Docentes, Docentes Orientadores y Docentes de Aula que asistieron a los encuentros celebrados en 12 regiones del país en el mes de noviembre de 2024
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid item xs={11} lg={10}>
                  <Typography className='text-info'>
                    Los docentes, desde sus diversos roles, se unieron para crear este manifiesto que refleja su compromiso con una educación inclusiva, equitativa y de calidad. Un llamado colectivo a transformar aulas, instituciones y comunidades con acciones concretas y colaborativas.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} justifyContent={'center'}>
                <Grid xs={11} lg={10} className='img-container'>
                  {/* <img src={manifiesto} alt='Manifiesto' className='info-img' onClick={() => handleOpen2()}/> */}
                  <iframe src={manifiestodi} className='info-img'></iframe>
                </Grid>
              </Grid>
              <Dialog open={open2} onClose={handleClose2} maxWidth='lg'>
                <DialogContent style={{ padding: 0 }}>
                  <img
                    src={manifiestodo}
                    alt='Imagen ampliada'
                    style={{
                      width: '100%',
                      height: 'auto',
                      display: 'block',
                    }}
                  />
                </DialogContent>
              </Dialog>
          </Grid>
        }

        {/* Docentes */}
        {openItem === 1 &&
          <Grid container className='Docentes-container backContraste'>
            <Grid item xs={12} className='text-container'>
              <Grid xs={11}>
                <Typography className='title'>
                  Videos testimoniales
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} className='text-container'>
              <Grid item xs={11} lg={10}>
                <Typography className='text-info'>
                  Conoce historias reales de docentes que comparten su experiencia transformadora con la educación inclusiva. Estos testimonios breves reflejan el impacto positivo de construir una comunidad educativa equitativa y diversa.
                </Typography>
              </Grid>
            </Grid>
            <Grid container className='cards-docentes-container'>
              <Grid item xs={12} lg={3} className='card-docentes-space'>
                <CardDocentes 
                  title="Transformando la educación a través de la participación activa en Arauca: Entrevista con Lourdes Medina"
                  img={testimony1}
                  imgDesc="Imagen testimonio"
                  autor="Por: Alejandro García Gómez"
                  testimonio={1}
                />
              </Grid>
              <Grid item xs={12} lg={3} className='card-docentes-space'>
                <CardDocentes 
                  title="Desafíos y Estrategias para Superar Barreras en Grupos Vulnerables de Aguachica, Cesar"
                  img={directivos}
                  imgDesc="Imagen testimonio"
                  autor="Por: Mónica Buelvas"
                  testimonio={2}
                />
              </Grid>
              <Grid item xs={12} lg={3} className='card-docentes-space'>
                <CardDocentes 
                  title="Educación Inclusiva: Una Mirada al Pasado, Presente y Futuro desde el Eje Cafetero"
                  img={testimony3}
                  imgDesc="Imagen testimonio"
                  autor="Por: Olga Lucía López"
                  testimonio={3}
                />
              </Grid>
              <Grid item xs={12} lg={3} className='card-docentes-space'>
                <CardDocentes 
                  title="La Esencia de la Docencia Transformadora en Nariño"
                  img={testimony4}
                  imgDesc="Imagen testimonio"
                  autor="Por: Karen Cruz y Lucía Güiza"
                  testimonio={4}
                />
              </Grid>
              <Grid item xs={12} lg={3} className='card-docentes-space'>
                <CardDocentes 
                  title="La Educación Inclusiva: Un Compromiso con la Diversidad y la Equidad en la Región Antioqueña"
                  img={testimony5}
                  imgDesc="Imagen testimonio"
                  autor="Por: Angelina Beltrán"
                  testimonio={5}
                />
              </Grid>
              <Grid item xs={12} lg={3} className='card-docentes-space'>
                <CardDocentes 
                  title="La Educación Equitativa y de Calidad: Pilar para el Desarrollo Integral en Funza, Cundinamarca"
                  img={testimony3}
                  imgDesc="Imagen testimonio"
                  autor="Por: Marcela Morera"
                  testimonio={6}
                />
              </Grid>
              <Grid item xs={12} lg={3} className='card-docentes-space'>
                <CardDocentes 
                  title="Hacia una Educación Más Inclusiva: Construyendo un Sistema Educativo para Todas y Todos en el Valle del Cauca"
                  img={testimony6}
                  imgDesc="Imagen testimonio"
                  autor="Por: Ana María Ángel Piedrahita"
                  testimonio={7}
                />
              </Grid>
            </Grid>

              <Grid container justifyContent={'center'} className='Transformacion-manifiesto-container backContraste'>
                <Grid xs={11}>
                  <hr className='separator'/>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Manifiesto nacional por una Educación Inclusiva, Equitativa y de Calidad de los Directivos Docentes, Docentes Orientadores y Docentes de Aula que asistieron a los encuentros celebrados en 12 regiones del país en el mes de noviembre de 2024
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid item xs={11} lg={10}>
                  <Typography className='text-info'>
                    Los docentes, desde sus diversos roles, se unieron para crear este manifiesto que refleja su compromiso con una educación inclusiva, equitativa y de calidad. Un llamado colectivo a transformar aulas, instituciones y comunidades con acciones concretas y colaborativas.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} justifyContent={'center'}>
                <Grid xs={11} lg={10} className='img-container'>
                  {/* <img src={manifiesto} alt='Manifiesto' className='info-img' onClick={() => handleOpen2()}/> */}
                  <iframe src={manifiestodo} className='info-img'></iframe>
                </Grid>
              </Grid>
              <Dialog open={open2} onClose={handleClose2} maxWidth='lg'>
                <DialogContent style={{ padding: 0 }}>
                  <img
                    src={manifiestodo}
                    alt='Imagen ampliada'
                    style={{
                      width: '100%',
                      height: 'auto',
                      display: 'block',
                    }}
                  />
                </DialogContent>
              </Dialog>
          </Grid>
        }

      </Grid>

      

      {/* Classroom section */}
      {/*<Grid container className='Transformacion-classroom-container'>
        <Grid container justifyContent={'center'}>
          <Grid xs={11}>
            <hr className='separator'/>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11} lg={8}>
            <Typography className='title'>
              Espacio virtual de interacción
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent={'center'}>
          <Grid xs={11} lg={4} className='img-container'>
            <img src={classroom} alt='Icono de classroom' className='info-img' />
          </Grid>
          <Grid item xs={12} lg={5} className='text-container'>
           <Grid xs={11}>
            <Typography className='text-info'>
              Un espacio diseñado para que directivos y docentes puedan interactuar con profesionales del convenio, aclarar inquietudes y compartir estrategias para promover la inclusión en sus instituciones.
            </Typography>
            <div className='button-container'>
                <Button type="submit" variant="contained" fullWidth className='submit-button'>
                    <Typography className='btn-txt'>
                      Classroom
                    </Typography>
                </Button>
            </div>
           </Grid> 
          </Grid>
        </Grid> 
      </Grid>*/}

    </>
  );
};