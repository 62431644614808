import './LiderazgoSection.scss';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import icon_play from '../../../assets/images/play_icon_2.svg';
import icon_download from '../../../assets/images/icon_download.png';
import icon_paper from '../../../assets/images/icon_paper.png';
import logo_educacion from '../../../assets/images/logo_educacion.png';
import Box from '@mui/material/Box';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import pieza_grafica_1 from '../../../assets/images/Pieza_grafica_1.png';
import pieza_grafica_2 from '../../../assets/images/Pieza_grafica_2.png';
import pieza_grafica_3 from '../../../assets/images/Pieza_grafica_3.png';
import pieza_grafica_4 from '../../../assets/images/Pieza_grafica_4.png';
import pdf_pieza_grafica_1 from '../../../assets/documents/piezagrafica1.pdf';
import pdf_pieza_grafica_2 from '../../../assets/documents/piezagrafica2.pdf';
import pdf_pieza_grafica_3 from '../../../assets/documents/piezagrafica3.pdf';
import pdf_pieza_grafica_4 from '../../../assets/documents/piezagrafica4.pdf';
import pdf_blb1 from '../../../assets/documents/estrategias_pedagogicas_basadas_DUA_moreno_y_cols.pdf';
import pdf_blb2 from '../../../assets/documents/familias_inclusion_y equidad_en_la_educación.pdf';
import pdf_blb3 from '../../../assets/documents/oportunidades_y_desafios_educación_Inclusiva.pdf';
import pdf_blb4 from '../../../assets/documents/tiflología_educación_inclusiva.pdf';
import encuentro_1 from '../../../assets/images/encuentro1.webp';
import encuentro_2 from '../../../assets/images/encuentro2.webp';
import encuentro_3 from '../../../assets/images/encuentro3.webp';
import encuentro_1a from '../../../assets/images/encuentro1a.webp';
import encuentro_1b from '../../../assets/images/encuentro1b.webp';
import encuentro_1c from '../../../assets/images/encuentro1c.webp';
import encuentro_1d from '../../../assets/images/encuentro1d.webp';
import encuentro_1e from '../../../assets/images/encuentro1e.webp';
import encuentro_1f from '../../../assets/images/encuentro1f.webp';
import encuentro_2a from '../../../assets/images/encuentro2a.webp';
import encuentro_2b from '../../../assets/images/encuentro2b.webp';
import encuentro_2c from '../../../assets/images/encuentro2c.webp';
import encuentro_2d from '../../../assets/images/encuentro2d.webp';
import encuentro_2e from '../../../assets/images/encuentro2e.webp';
import encuentro_2f from '../../../assets/images/encuentro2f.webp';
import encuentro_3a from '../../../assets/images/encuentro3a.webp';
import encuentro_3b from '../../../assets/images/encuentro3b.webp';
import encuentro_3c from '../../../assets/images/encuentro3c.webp';
import encuentro_3d from '../../../assets/images/encuentro3d.webp';
import encuentro_3e from '../../../assets/images/encuentro3e.webp';
import ico_download from '../../../assets/images/icon-download.svg';
import img_blb1 from '../../../assets/images/img_bibl1.png';
import img_blb2 from '../../../assets/images/img_bibl2.png';
import img_blb3 from '../../../assets/images/img_bibl3.png';
import img_blb4 from '../../../assets/images/img_bibl4.png';
import img_blb5 from '../../../assets/images/img_bibl5.png';
import img_blb6 from '../../../assets/images/img_bibl6.png';
import img_blb7 from '../../../assets/images/img_bibl7.png';
import img_blb8 from '../../../assets/images/img_bibl8.png';
import img_blb9 from '../../../assets/images/img_bibl9.png';
import img_blb10 from '../../../assets/images/img_bibl10.png';
import img_blb11 from '../../../assets/images/img_bibl11.png';
import img_blb12 from '../../../assets/images/img_bibl12.png';
import img_blb13 from '../../../assets/images/img_bibl13.png';
import img_blb14 from '../../../assets/images/img_bibl14.png';
import img_blb15 from '../../../assets/images/img_bibl15.png';
import img_blb16 from '../../../assets/images/img_bibl16.png';
import img_blb17 from '../../../assets/images/img_bibl17.png';
import { useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import Badge from '@mui/material/Badge';
import { Button } from '@mui/material';
// @ts-ignore

const CardInfo = ({ text, link, color, img }: any) => {

  return (
      <>  
        <a href={link} target='__blank' className='link-cards'>
          <Grid container className={`card-info-container ${color}`}>
            <Grid container item xs={12} className='text-container'>
              {img && (
                <Grid item className='img-container'>
                  <img src={img} alt="Descripción de la imagen" className='card-image' />
                </Grid>
              )}
              <Typography className='text'>
                {text}
              </Typography>
            </Grid>
          </Grid>
        </a>
      </>
  );
}

const CardInfo2 = ({ img, imgDesc, title, text, images, subtitle }: any) => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <> 
        <Grid container className='card-info-container2'>
          <Grid container item xs={12} className='text-container'>
            <Typography className='title'>
              {title}
            </Typography>
            {subtitle && <small className='subtitle'>{subtitle}</small>}
          </Grid>
          <Grid container item xs={12} className='img-item'>
            <img src={img} alt={imgDesc} className='info-img' onClick={() => handleOpen()}/>
          </Grid>
          <Grid container item xs={12} className='text-container'>
            <Typography className='text'>
              {text}
            </Typography>
          </Grid>
          <Dialog open={open} onClose={handleClose} maxWidth='lg' className='modal-encuentros'>
            <DialogContent style={{ padding: 15 }}>
              <Grid container item xs={12} className='text-container'>
                <Typography className='title'>
                  {title}
                </Typography>
              </Grid>
              <Swiper
                modules={[Navigation]}
                spaceBetween={10}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                className='encuentros-carousel'
              >
              {images.map((image: any, index: any) => (
                <SwiperSlide key={index} className='encuentros-carousel-slide'>
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className='encuentros-img'
                    style={{ width: "100%", borderRadius: 8 }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Grid container item xs={12} className='text-container'>
              <Typography className='text-info'>
                {text}
              </Typography>
            </Grid>
          </DialogContent>
        </Dialog>
        </Grid>
      </>
  );
}

const CardText = ({ text, position, link, className }: any) => {

  return (
      <>  
        <hr className={position}/>
        <a href={link} target='__blank' className='link-cards-text'>
          <Grid container className='card-info-container'>
            <Grid container item xs={12} className={`text-container ${className}`}>
              <Typography className='text'>
                {text}
              </Typography>
            </Grid>
          </Grid>
        </a>
      </>
  );
}

export const LiderazgoSection = () => {

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleOpen = (image: string) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const images = [
    pieza_grafica_4,
    pieza_grafica_3,
    pieza_grafica_2,
    pieza_grafica_1,
  ];
  const pdfs = [
    pdf_pieza_grafica_1,
    pdf_pieza_grafica_2,
    pdf_pieza_grafica_3,
    pdf_pieza_grafica_4,
  ];

  const img_encuentro1 = [
    encuentro_1,
    encuentro_1a,
    encuentro_1b,
    encuentro_1c,
    encuentro_1d,
    encuentro_1e,
    encuentro_1f,
  ];

  const img_encuentro2 = [
    encuentro_2,
    encuentro_2a,
    encuentro_2b,
    encuentro_2c,
    encuentro_2d,
    encuentro_2e,
    encuentro_2f,
  ];

  const img_encuentro3 = [
    encuentro_3,
    encuentro_3a,
    encuentro_3b,
    encuentro_3c,
    encuentro_3d,
    encuentro_3e,
  ];
  
  return (
    <>

      {/* Banner section */}
      <Grid container className='Liderazgo-banner-container'>
        <Grid item xs={12} lg={5} className='logos-left'>
          <img className="logo-1" alt="Logo Ministerio de educación" src={logo_educacion} />
        </Grid>
        <Grid item xs={12} lg={7} className='content-right'>
          <h1 className='title'><b>El Liderazgo del Ministerio de Educación Nacional</b></h1>
        </Grid>
      </Grid>

      {/* Description section */}
      <Grid container className='Liderazgo-description-container backContraste'>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11} lg={10}>
            <Typography className='title'>
              Lideramos transformaciones en culturas, políticas y prácticas para garantizar una educación inclusiva y equitativa para todas las personas
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11} lg={10}>
            <Typography className='text-info'>
              En el Ministerio de Educación Nacional, somos agentes de cambio que conectan políticas nacionales con su implementación en los territorios. Trabajamos en equipo con secretarías, instituciones y comunidades para construir un sistema educativo que respete, valore y responda a la diversidad, garantizando educación de calidad para todos.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Media section */}
      <Grid container className='Liderazgo-media-container backContraste'>
        <Grid container item xs={12} lg={6} className='media-section'>
          <Grid item xs={10}>
            <Grid item xs={12} className='info-container'>
              <Typography className='title'>
                Inclusión: reflexión y acción
              </Typography>
            </Grid>
            <Grid item xs={12} className='info-container'>
              {/* <video controls className='video-item'>
                <source src="https://drive.google.com/file/d/1thz-P40UT09Pi7Y5iufSmz3nftjYnoqE/preview" type="video/mp4" />
                Tu navegador no soporta el formato de video.
              </video> */}
              <iframe src="https://drive.google.com/file/d/1thz-P40UT09Pi7Y5iufSmz3nftjYnoqE/preview" className='video-item-iframe' allowFullScreen>
              </iframe>
            </Grid>
            <Grid container xs={12} className='info-container'>
              <div className='video-item'>
                <Grid item xs={0} md={2}>
                  <img src={icon_play} alt='Icono de play' className='info-img' />
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Typography className='text'>
                    Descubre cómo hacer realidad la educación para todos en Colombia. 
                    Este video presenta los lineamientos clave del documento <i>"Educación todas las personas sin excepción. [...]"</i>.
                  </Typography>
                  <div className="badge-wrapper">
                    <Badge 
                      badgeContent="Recursos creados bajo el Convenio CO1.PCCNTR.672706 (2024)" 
                      color="primary" 
                      className='badge-container custom-badge'
                    />
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={6} className='media-section'>
          <Grid item xs={10}>
            <Grid item xs={12} className='info-container'>
              <Typography className='title'>
                Territorios que se transforman: Narrativas de equidad
              </Typography>
            </Grid>
            <Grid item xs={12} className='info-container'>
              <iframe src="https://drive.google.com/file/d/1hRprrB8gSoyTNHEF5-KO6QsFM21814_Y/preview" className='video-item-iframe' allowFullScreen>
              </iframe>
            </Grid>
            <Grid container xs={12} className='info-container'>
              <div className='video-item'>
                <Grid item xs={0} md={2}>
                  <img src={icon_play} alt='Icono de play' className='info-img' />
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Typography className='text'>
                    Descubre cómo 12 regiones de Colombia están transformando la educación. En este documental, escucharás las voces de estudiantes, docentes y familias que están construyendo escuelas más justas y equitativas.
                  </Typography>
                  <div className="badge-wrapper">
                    <Badge 
                      badgeContent="Recursos creados bajo el Convenio CO1.PCCNTR.672706 (2024)" 
                      color="primary" 
                      className='badge-container custom-badge'
                    />
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* images section */}
      <Grid container className='Liderazgo-images-container backContraste'>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11}>
            <Typography className='title'>
              Incentiva la toma de conciencia en tu territorio
              <br />
              <small>Puedes usar estas piezas gráficas para incentivar la toma de conciencia en tu dependencia</small>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className='graphic-container'>
          {images.map((image, index) => (
            <Grid
              item
              container
              xs={12}
              lg={3}
              justifyContent={'center'}
              key={index}
            >
              <img
                src={image}
                alt={`Pieza gráfica ${index + 1}`}
                className='graphic-img'
                onClick={() => handleOpen(image)}
                style={{ cursor: 'pointer' }}
              />
              <Button
                variant="contained"
                className="download-button"
                href={pdfs[index]}
                download
                style={{ marginTop: '10px' }}
              >
                <img src={ico_download} alt="Descargar" />
                Descargar PDF
              </Button>
            </Grid>
          ))}

          <Dialog open={open} onClose={handleClose} maxWidth='lg'>
            <DialogContent style={{ padding: 0 }}>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt='Imagen ampliada'
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'block',
                  }}
                />
              )}
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>

      {/* encuentros section */}
      <Grid container className='Liderazgo-encuentros-container backContraste'>
        <Grid container justifyContent={'center'}>
          <Grid xs={11}>
            <hr className='separator'/>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11} lg={9}>
            <Typography className='title'>
              Construyendo una Educación para Todas las personas: encuentros de reflexión y diálogo. 
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11} lg={9}>
            <Typography className='text-info'>
            Momentos que nos mostraron cómo cada persona del Ministerio de Educación Nacional puede hacer la diferencia en la construcción de una educación inclusiva y equitativa. Revive estos espacios a través de nuestras memorias fotográficas.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4} className='card-container right-border'>
          <CardInfo2 
            img={encuentro_1}
            images={img_encuentro1}
            imgDesc="Primer Encuentro"
            title="Articulemos esfuerzos en el marco de la educación inclusiva"
            subtitle="15 de noviembre de 2024"
            text="En un día diferente, junto con el Centro de Rehabilitación para Adultos Ciegos, dialogamos sobre nuevas formas de entender y hacer realidad la educación inclusiva. Descubre en imágenes cómo vivimos esta experiencia."
          />
        </Grid>
        <Grid item xs={12} lg={4} className='card-container right-border'>
          <CardInfo2 
            img={encuentro_2}
            images={img_encuentro2}
            imgDesc="Segundo Encuentro"
            title="Conmemoración día internacional de las personas con discapacidad"
            subtitle="3 de diciembre de 2024"
            text="Un día de descubrimientos donde aprendimos elementos básicos de la Lengua de Señas Colombiana a través de espacios itinerantes, y rendimos homenaje a Jorge Andrés Colmenares, quien, desde su experiencia con la discapacidad visual, enriqueció el camino hacia una educación más inclusiva. Revive estos momentos de conexión y aprendizaje."
          />
        </Grid>
        <Grid item xs={12} lg={4} className='card-container'>
          <CardInfo2 
            img={encuentro_3}
            images={img_encuentro3}
            imgDesc="Tercer Encuentro"
            title="Jugando transformamos imaginarios"
            subtitle="5 de diciembre de 2024"
            text="Rompimos la rutina laboral con retos y diálogos inesperados que nos llevaron a cuestionar nuestras creencias sobre la educación inclusiva. Entre risas y reflexiones, cada persona descubrió cómo sus ideas pueden transformar la realidad educativa. Descubre en imágenes cómo el juego nos unió por una educación para todas las personas."
          />
        </Grid>
      </Grid>

      {/* Repository section */}
      <Grid container className='Liderazgo-repository-container backContraste'>
        <Grid container justifyContent={'center'}>
          <Grid xs={11}>
            <hr className='separator'/>
          </Grid>
        </Grid>
        <Grid container item xs={12} className='text-container'>
          <Grid xs={11}>
            <Typography className='title'>
              Biblioteca de políticas y lineamientos
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12} lg={8} className='resources-section'>
            <Grid container className='cards-info-container'>
              <Grid container item xs={12} lg={2} className='img-item'>
                <img src={icon_download} alt="Icono de descarga" className='info-img' />
              </Grid>
              <Grid container item xs={10} lg={9} className='text-container-2'>
                <Typography className='text'>
                  Lineamientos, recursos formativos y orientaciones
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                img={img_blb1}
                link="https://www.colombiaaprende.edu.co/sites/default/files/files_public/archivos_contenidos/AF%20LINEAMIENTOS%20DE%20POLI%CC%81TICA%20ACCESIBLE.pdf"
                text={<>Lineamientos de política para la inclusión y la equidad en educación. Educación para todas las personas sin excepción  (MEN, 2021). 
                 </>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                img={img_blb2}
                link="https://www.mineducacion.gov.co/1780/articles-363488_recurso_17.pdf"
                text={<>Inclusión y equidad: hacia la construcción de una política de educación inclusiva para Colombia.
                </>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                img={img_blb3}
                link="https://www.mineducacion.gov.co/1780/articles-363488_recurso_27.pdf"
                text={<>Inclusión y equidad en el proceso de evaluación de la calidad de la educación.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                img={img_blb4}
                link="https://www.colombiaaprende.edu.co/contenidos/coleccion/emociones-conexion-vital"
                text={<>Emociones, Conexión Vital: inclusión y equidad para trayectorias educativas completas.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                img={img_blb4}
                link="https://www.saldarriagaconcha.org/centro-de-recursos-maestros-por-la-inclusion/?gad_source=1&gclid=Cj0KCQjw1Yy5BhD-ARIsAI0RbXZEScUmvsrz_iZaB73SsxRov2L_BzAGpBYLFdYSB1V8j8OVSqzVeVgaAnx-EALw_wcB"
                text={<> Centro de Recursos Maestros por la Inclusión. </>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                img={img_blb5}
                link="https://www.saldarriagaconcha.org/wp-content/uploads/2019/01/Libro2-Toma-de-Conciencia.pdf"
                text={<>Toma de conciencia, sentir, pensar y actuar.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                img={img_blb6}
                link="https://www.mineducacion.gov.co/1759/articles-360293_foto_portada.pdf"
                text={<>Documento de orientaciones técnicas, administrativas y pedagógicas para la atención educativa a estudiantes con discapacidad en el marco de la educación inclusiva.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                img={img_blb7}
                link="https://escuelasecretarias.mineducacion.gov.co/sites/default/files/2022-10/9.20_ORIENTACIONES_%20TALENTOS%20EXEPCIONALES.pdf"
                text={<>Documento de orientaciones técnicas, administrativas y pedagógicas para la atención educativa a estudiantes con capacidades y/o con talentos excepcionales en el marco de la educación inclusiva.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                img={img_blb8}
                link="https://www.mineducacion.gov.co/1780/articles-360295_foto_portada.pdf"
                text={<>Orientaciones generales para la escuela y la familia en la atención educativa a estudiantes con capacidades y talentos excepcionales</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                img={img_blb9}
                link="https://escuelasecretarias.mineducacion.gov.co/sites/default/files/2022-08/orientacionparalatransicion.pdf"
                text={<>Orientaciones para la transición educativa de los estudiantes con discapacidad y con capacidades o talentos excepcionales en la educación inicial, básica y media.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                img={img_blb10}
                link="https://www.colombiaaprende.edu.co/recurso-coleccion/guias-y-cartillas-orientadoras"
                text={<>Colección Trayectorias Educativas Completas. Guías y cartillas orientadoras.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                img={img_blb11}
                link=""
                text={<>Recursos del grupo de investigación.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                img={img_blb12}
                link="https://udlguidelines.cast.org/static/udlg3-graphicorganizer_spanish_update_8142024.pdf"
                text={<>Guía DUA 3.0. </>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                img={img_blb13}
                link={"https://www.cast.org/about/about-cast"}
                text={<>Página web CAST.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                img={img_blb14}
                link={pdf_blb1}
                text={<>Estrategias pedagógicas basadas DUA moreno y cols</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                img={img_blb15}
                link={pdf_blb2}
                text={<>Tiflología educación inclusiva</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                img={img_blb16}
                link={pdf_blb3}
                text={<>Familias Inclusión y Equidad en la educación</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                img={img_blb17}
                link={pdf_blb4}
                text={<>Oportunidades y desafios en la educación inclusiva</>}
              />
            </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12} lg={4} className='law-section'>
          <Grid container className='cards-info-container'>
            <Grid container item xs={12} lg={4} className='img-item'>
              <img src={icon_paper} alt="Icono de hoja de papel" className='info-img' />
            </Grid>
            <Grid container item xs={12} lg={5} className='text-container-2'>
              <Typography className='text'>
                Normativa
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={11} className='cards-container-2'>
            <Box
              sx={{
                maxHeight: '1400px',
                overflowY: 'auto',
              }}
            >
              <Grid item xs={12} className='text-container-3'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Derecho a la educación en el marco de la Inclusión y la equidad en la educación
                  </Typography>
                </Grid>
              </Grid>
              <CardText
                link="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=22106"
                text={<><b>Ley 1098 de 2006. 
                </b></>}
                position="first"
                className="bullet color-1" 
              />
              <CardText
                link="https://www.mineducacion.gov.co/1759/articles-157662_archivo_pdf.pdf"
                className="bullet color-3"
                text={<><b>Circular No. 007 de 2008.
                </b> </>}
              />
              <CardText
                link="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=38741"
                className="bullet color-1"
                text={<><b>Ley 1381 de 2010.
                </b> </>}
              />
              <CardText
                link="https://www.icbf.gov.co/cargues/avance/compilacion/docs/decreto_1470_2013.htm"
                className="bullet color-2"
                text={<><b>Decreto 2500 de 2010.
                </b> </>}
              />
              <CardText
                link="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=77913"
                className="bullet color-2"
                text={<><b>Decreto 1075 de 2015.
                </b> </>}
              />
              <CardText
                link="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=66614"
                className="bullet color-2"
                text={<><b>Decreto 2383 de 2015.
                </b> </>}
              />
              <CardText
                link="https://colaboracion.dnp.gov.co/CDT/Conpes/Econ%C3%B3micos/3988.pdf"
                className="bullet color-6"
                text={<><b>Documento Conpes Social 3988 de 2020.
                </b> </>}
              />
              <CardText
                link="https://www.cundinamarca.gov.co/wcm/connect/4d9308a2-d455-4873-9711-b3a3c5bf0644/Resoluci%C3%B3n+1519+de+2020.pdf?MOD=AJPERES&CONVERT_TO=url&CACHEID=ROOTWORKSPACE-4d9308a2-d455-4873-9711-b3a3c5bf0644-oVo7PWv"
                className="bullet color-4"
                text={<><b>Resolución N° 001519 de 2020.
                </b> </>}
              />
              <Grid item xs={12} className='text-container-3'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Derecho a la educación de las personas con discapacidad en el marco de la educación inclusiva y equitativa
                  </Typography>
                </Grid>
              </Grid>
              <CardText
                link="https://www.un.org/esa/socdev/enable/documents/tccconvs.pdf"
                className="bullet color-7"
                text={<><b>Convención sobre los derechos de las personas con discapacidad
                </b> (ONU, 2006). </>}
              />
              <CardText
                link="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=87040"
                className="bullet color-2"
                text={<><b>Decreto 1421 de 2017.
                </b> </>}
              />
              <CardText
                link="https://www.minsalud.gov.co/sites/rid/Lists/BibliotecaDigital/RIDE/DE/DIJ/resolucion-1197-de-2024.pdf"
                className="bullet color-4"
                text={<><b>Resolución 1197 de 2024.
                </b></>}
              />
              <Grid item xs={12} className='text-container-3'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Derecho a la educación de las personas en condición de enfermedad en el marco de la educación inclusiva y equitativa
                  </Typography>
                </Grid>
              </Grid>
              <CardText
                link="https://www.corteconstitucional.gov.co/relatoria/2009/T-294-09.htm"
                className="bullet color-5"
                text={<><b>Sentencia T-294/09.
                </b></>}
              />
              <CardText
                link="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=188289"
                className="bullet color-1"
                text={<><b>Ley 2216 de 2022.
                </b></>}
              />
              <CardText
                link="https://www.icbf.gov.co/cargues/avance/compilacion/docs/decreto_1470_2013.htm"
                className="bullet color-2"
                text={<><b>Decreto 1470 de 2013. 
                </b></>}
              />
              <Grid item xs={12} className='text-container-3'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Derecho a la educación de las personas con capacidades y/o talentos excepcionales 
                  </Typography>
                </Grid>
              </Grid>
              <CardText
                link="https://www.icbf.gov.co/cargues/avance/compilacion/docs/su-1149_2000.htm"
                className="bullet color-6"
                text={<><b>SU – 1149 de 2000 
                </b></>}
              />
              <CardText
                link="https://www.alcaldiabogota.gov.co/sisjur/normas/Norma1.jsp?i=54730&dt=S"
                className="bullet color-5"
                text={<><b>Sentencias T-571 de 2013  
                </b></>}
              />
              <CardText
                link="https://www.corteconstitucional.gov.co/relatoria/2009/t-294-09.htm "
                className="bullet color-5"
                text={<><b>Sentencia T-294 de 2009 
                </b> </>}
              />
              <CardText
                link="https://www.icbf.gov.co/cargues/avance/compilacion/docs/decreto_1470_2013.htm"
                className="bullet color-1"
                text={<><b>Ley 2216 de 2022
                </b> </>}
              />
              
            </Box>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
};