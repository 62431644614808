import React from 'react';
import Grid from '@mui/material/Grid';
import { TestimoniosSection } from '../../components/VideosTestimoniales';

export const VideosTestimoniales = () => {
  return (
    <>
      <Grid container className='landing-container'>
        <Grid item xs={12}>
            <TestimoniosSection />
        </Grid>
      </Grid>
    </>
  );
};