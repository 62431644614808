import './Navigation.scss';
import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import logo_educacion from '../../../assets/images/logo_educacion.png';
import logo_unal from '../../../assets/images/logo_unal.png';
import logo_programa from '../../../assets/images/logo_educacion_inclusiva.png';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goToHome = () => {
    navigate('/');
  };

  const goToLiderazgo = () => {
    navigate('/liderazgo-desde-el-ministerio');
  };

  const goToRegiones = () => {
    navigate('/cambio-en-las-regiones');
  };

  const goToAula = () => {
    navigate('/transformacion-desde-el-aula');
  };

  const goToVoces = () => {
    navigate('/voces-familias-y-estudiantes');
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <header className="navigation-container">
		<Grid container className='bot-navigation'>
			<div className={`section-container nav-item ${isActive('/') ? 'active' : ''}`} onClick={goToHome}>
				<p>inicio</p>
			</div>
			<div className="section-container">
				<p><b>Iniciativas en acción:</b></p>
			</div>
			<div className={`section-container nav-item ${isActive('/liderazgo-desde-el-ministerio') ? 'active' : ''}`} onClick={goToLiderazgo}>
				<p>Liderazgo desde el Ministerio</p>
			</div>
			<div className='right-border'></div>
			<div className={`section-container nav-item ${isActive('/cambio-en-las-regiones') ? 'active' : ''}`} onClick={goToRegiones}>
				<p>El cambio en las regiones</p>
			</div>
			<div className='right-border'></div>
			<div className={`section-container nav-item ${isActive('/transformacion-desde-el-aula') ? 'active' : ''}`} onClick={goToAula}>
				<p>Transformación desde el aula</p>
			</div>
			<div className='right-border'></div>
			<div className={`section-container nav-item ${isActive('/voces-familias-y-estudiantes') ? 'active' : ''}`} onClick={goToVoces}>
				<p>Voces de las familias y estudiantes</p>
			</div>
		</Grid>
    </header>
  );
};

export default Navigation;
