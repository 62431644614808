import './VocesSection.scss';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import logo_educacion from '../../../assets/images/logo_educacion.png';
import logo_unal from '../../../assets/images/logo_unal.png';
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Dialog, DialogContent, Button } from '@mui/material';
import testimony1F from '../../../assets/images/NARIÑO_F.webp';
import testimony2F from '../../../assets/images/ANDINA_F.webp';
import testimony1E from '../../../assets/images/CARIBE_E.webp';
import testimony2E from '../../../assets/images/NARIÑO_E.webp';
import testimony3E from '../../../assets/images/EJE_CAFETERO_E.webp';
import testimony4E from '../../../assets/images/CESAR_E.webp';
import manifiesto from '../../../assets/images/manifiesto.png';
import manifiestof from '../../../assets/documents/manifiesto_familias.pdf';
import manifiestoe from '../../../assets/documents/manifiesto_estudiantes.pdf';
import { Link } from 'react-router-dom';

const CardHistorias = ({ title, text, fullText, video }: any) => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <>  
          <Grid container className='card-historias-container' onClick={() => handleOpen()}>
            <Grid container item xs={12} className='title-container'>
              <Typography className='title'>
                {title}
              </Typography>
            </Grid>
            <Grid container item xs={12} className='img-item'>
              <iframe src={video} className='info-img'>
              </iframe>
            </Grid>
            <Grid container item xs={12} className='text-container'>
              <Typography className='text'>
                {text}
              </Typography>
            </Grid>
          </Grid>
          <Dialog open={open} onClose={handleClose} maxWidth='lg' className='modal-historias'>
            <DialogContent style={{ padding: 20 }}>
              <Grid container item xs={12} className='text-container'>
                <Typography className='title'>
                  {title}
                </Typography>
              </Grid>
              <Grid container item xs={12} className='video-container'>
                <iframe src={video} className='video-item' allowFullScreen>
                </iframe>
              </Grid>
              <Grid container item xs={12} className='text-container'>
                <Typography className='text-info'>
                  {fullText}
                </Typography>
              </Grid>
            </DialogContent>
        </Dialog>
      </>
  );
}

const CardTestimonios = ({ title, img, imgDesc, autor, action, testimonio, type }: any) => {

  return (
      <>  
          <Grid container className='card-testimonios-container' onClick={action}>
            <Grid container item xs={12} className='img-item'>
              <img src={img} alt={imgDesc} className='info-img' />
            </Grid>
            <Grid container item xs={12} className='title-container'>
              <Typography className='title'>
                {title}
              </Typography>
            </Grid>
            <Grid container item xs={12} className='text-container'>
              <Typography className='text'>
                {autor}
              </Typography>
            </Grid>
            <div className='button-container'>
              <Link to={`/voces-familias-y-estudiantes/testimonios/${testimonio}?query=${type}`}>
                <Button variant="contained" fullWidth className='testimony-button'>
                    <Typography className='btn-txt'>
                      Ver testimonio
                    </Typography>
                </Button>
              </Link>
            </div>
          </Grid>
      </>
  );
}

export const VocesSection = () => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = useState(false);

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [openItem, setOpenItem] = useState(0);

  const toggleItem = (itemIndex: number) => {
    setOpenItem(itemIndex);
  };

  useEffect(() => {
    const items = document.querySelectorAll('[id^="item-"]');
    
    items.forEach((item) => {
      item.classList.remove('active-item');
    });

    const activeItem = document.getElementById(`item-${openItem}`);
    if (activeItem) {
      activeItem.classList.add('active-item');
    }
  }, [openItem]);

  const familias_info = [
    { title: "Amazonas",
      description: "Marcilei, la madre, ha asumido un papel fundamental en el aprendizaje...",
      fullDescription: "Marcilei, la madre, ha asumido un papel fundamental en el aprendizaje de la Lengua de Señas Colombiana y destaca la dedicación de los docentes de la institución, así como del profesor Pipe, quien le enseña fútbol sala. Además, también participan en prácticas de natación. Ella está profundamente comprometida con el proceso educativo de sus hijos y trabaja para apoyarlos en su camino hacia la independencia.",
      video: "https://drive.google.com/file/d/1WfGL8YTNkNy4jj5NySZYWmDCwwOhLBTa/preview"
    },
    { title: "San Andrés",
      description: 'Maritza describe a Jeisen como "un pez en el agua"...',
      fullDescription: 'Maritza describe a Jeisen como "un pez en el agua". Él sueña con ser pastor o doctor cuando crezca. Durante mucho tiempo, Maritza buscó una escuela donde pudiera estudiar y destaca cuánto ha aprendido Jeisen en su institución actual. El trabajo colaborativo entre la familia, el docente y la institución ha generado avances significativos en las habilidades comunicativas, sociales y afectivas de Jeisen, mientras que la comunidad educativa ha aprendido a valorar y respetar la diversidad.',
      video: "https://drive.google.com/file/d/1GtN2mcpIzNKMvzKQa7C-bITRerGtyHPO/preview"
    },
    { title: "Eje cafetero",
      description: "Su madre, Jenny Mejía, menciona que a Brayan le apasiona la tecnología...",
      fullDescription: "Su madre, Jenny Mejía, menciona que a Brayan le apasiona la tecnología, disfruta montar bicicleta y asistir al gimnasio. Aunque inicialmente enfrentó dificultades para aprender, con el apoyo de la Secretaría de Educación logró ingresar a la institución educativa. Gracias al acompañamiento de la psicoorientadora y su esfuerzo, Brayan ha avanzado tanto que está a punto de graduarse. Jenny resalta la importancia de perseverar y tocar puertas para lograr el progreso de sus hijos.",
      video: "https://drive.google.com/file/d/1pv-4wz6h0yw7PigBSqbVA8XKf_5LOFrZ/preview"
    },
    { title: "Antioquia",
      description: "Su madre, Maribel Herrera, lidera el proceso de crianza de Julieta...",
      fullDescription: "Su madre, Maribel Herrera, lidera el proceso de crianza de Julieta y gestiona los apoyos necesarios en educación y salud. Maribel destaca las habilidades de su hija en natación y patinaje. Del encuentro de familias, se llevó la valiosa lección de que no está sola en los retos que ha enfrentado para lograr la inclusión de su hija. Además, subraya la importancia de realizar más espacios como este, donde las familias puedan compartir experiencias y apoyarse mutuamente.",
      video: "https://drive.google.com/file/d/1nnV1KZMmYtIWiinyYxHm8cMOfnB0Rhsy/preview"
    },
    { title: "Santander",
      description: "Zuria es una madre comprometida que ha desempeñado...",
      fullDescription: "Zuria es una madre comprometida que ha desempeñado un papel fundamental en la educación y desarrollo de Cristian, sirviendo como enlace constante entre su hijo y los servicios de salud. Zuria resalta el papel fundamental del colegio en su desarrollo, especialmente gracias a la actitud comprometida de la rectora y al apoyo de los docentes, quienes han sido clave en su proceso pedagógico y educativo.",
      video: "https://drive.google.com/file/d/1vGqa7ajiKIKoyphvZOcsmQ94oLsi0Vq9/preview"
    },
    { title: "Caribe",
      description: "La señora Aida Cuevas enfrentó la barrera de creer que, debido al...",
      fullDescription: "La señora Aida Cuevas enfrentó la barrera de creer que, debido al diagnóstico de su hijo, no lograría conseguirle un cupo escolar. Sin embargo, gracias al proceso de las Aulas Hospitalarias (AH) y al apoyo de la Secretaría de Educación, Cristian recibió acompañamiento pedagógico y apoyo emocional, lo que le permitió, al salir del hospital, integrarse a un aula regular. Además, Aida destaca el valioso apoyo mutuo entre las madres de los estudiantes que también estaban en el hospital.",
      video: "https://drive.google.com/file/d/1qcMV41AgC0kPB1M3HPuKepxYD2SGwEol/preview"
    },
    { title: "Valle del cauca",
      description: "La madre de Juan Felipe, Olga Quistial, tuvo un papel crucial en este...",
      fullDescription: "La madre de Juan Felipe, Olga Quistial, tuvo un papel crucial en este proceso. Aunque al principio le costó aceptar el diagnóstico de su hijo, debido a que siempre lo consideró un joven destacado por su talento musical, pronto comprendió la importancia de los ajustes pedagógicos. Su compromiso creció y, junto con la institución, ayudó a Juan  Felipe a superar las dificultades académicas.",
      video: "https://drive.google.com/file/d/1TXxRRJ8UH_qheGDb5oEtZDT-y7x3TH9K/preview"
    },
    { title: "Arauca",
      description: "Vincular nuevamente a Yurissa en el proceso educativo  implicó...",
      fullDescription: "Vincular nuevamente a Yurissa en el proceso educativo  implicó la articulación de la familia con el colegio y la sensibilización de la docente de apoyo a la inclusión, quien trabajó para cambiar los prejuicios de la cuidadora acerca de la educación de Yurisa. ",
      video: "https://drive.google.com/file/d/1_p-OP7BarsYQLM8v_nLxMBne85jE85wc/preview"
    },
    { title: "Tumaco",
      description: "Paola Blandón la mamá de Evelin destaca la autonomía en los...",
      fullDescription: "Paola Blandón la mamá de Evelin destaca la autonomía en los procesos educativos, y su interés en el deporte y la danza .",
      video: "https://drive.google.com/file/d/1FHo7YYoGW_16nDE9SDCegLtAaESizuxA/preview"
    },
    { title: "Cesar",
      description: "Mónica, la madre de Alejandra, habla con cariño de Aguachica, el lugar...",
      fullDescription: "Mónica, la madre de Alejandra, habla con cariño de Aguachica, el lugar donde su hija nació, y destaca cuánto disfrutan compartir en familia. Resalta el valioso trabajo en equipo entre la profesional de apoyo, los docentes y la familia, subrayando cómo este acompañamiento ha fortalecido el proceso educativo de Alejandra. Gracias a este apoyo, ha notado un aumento en la participación de su hija durante las clases.",
      video: "https://drive.google.com/file/d/19f981Y-fO2dID7jRrQo-kTIrq-jkKkRK/preview"
    },
    { title: "Andina- Funza",
      description: "La familia de Juan Gabriel ha sido clave en su desarrollo. Su madre...",
      fullDescription: "La familia de Juan Gabriel ha sido clave en su desarrollo. Su madre, Sandra Jaimes, es ama de casa y considera a Juan Gabriel como su mayor amor.  Ha sido un apoyo constante, ayudando a su hijo a organizar sus tareas y a trabajar de manera disciplinada. La familia ha trabajado estrechamente con la institución educativa para garantizar que las necesidades de Juan Gabriel sean atendidas, y han fomentado un ambiente que refuerza su autoestima y autonomía. Además, Sandra destaca la importancia de que los docentes implementen estrategias para apoyar a los estudiantes con discapacidad.",
      video: "https://drive.google.com/file/d/1JS6l_vC_c6jX6MPokdDW5v8iB1WRbj89/preview"
    },
    { title: "Andina- Duitama",
      description: "Loren vive con sus padres, su madre biológica Jeidy, de 31 años...",
      fullDescription: "Loren vive con sus padres, su madre biológica Jeidy, de 31 años, y su padrastro Ariel, de 40, quienes son propietarios de una panadería. Residen en el barrio El Mirto, en Duitama, y mantienen una buena relación familiar. Los padres han trabajado activamente en desarrollar las habilidades ocupacionales de Loren y en fomentar su autonomía en todos los aspectos de su vida.",
      video: "https://drive.google.com/file/d/1STrEl6DSw2zgYhUcgFFuFukFuZWd_nnJ/preview"
    },
    { title: "Nariño - Pasto",
      description: "En este video, Olga Barrera, madre de Sebastián y trabajadora en reciclaje...",
      fullDescription: "En este video, Olga Barrera, madre de Sebastián y trabajadora en reciclaje, comparte recuerdos de la infancia de su hijo, destacando su gran entusiasmo por los carros. También habla sobre el proceso de apoyo brindado por la institución educativa, que no solo ayudó a Sebastián a aprender a leer y escribir, sino que además le permitió mantenerse junto a compañeros de su misma edad. El video refleja el importante respaldo familiar que ha recibido Sebastián, tanto de su madre como de su hermana, y resalta la importancia de las terapias en el proceso de Sebastian",
      video: "https://drive.google.com/file/d/1VMxUe7Xzvb0gYOBvtzrWSrMAK4v_NyHz/preview"
    },

  ];

  const estudiantes_info = [
    { title: "Amazonas",
      description: "Fabio y Edward Almeida son dos hermanos con discapacidad auditiva...",
      fullDescription: "Fabio y Edward Almeida son dos hermanos con discapacidad auditiva que cursan grado noveno en la Escuela Normal Superior Marceliano Eduardo Canyes Santacana, en Leticia, Amazonas. Viven con sus padres, Marcilei Díaz y Fabio Alonso Almeida, y una hermana mayor. Su familia, de escasos recursos, reside en Tabatinga, Brasil, y enfrenta retos como la falta de transporte para desplazarse. A pesar de las dificultades, ambos jóvenes tienen intereses fuertes en el deporte, lo que ha facilitado su integración social y ha sido clave para su proceso educativo.",
      video: "https://drive.google.com/file/d/1caYrqQufz-eqZqX1hK8-h9WZtp6IZtWu/preview"
    },
    { title: "San Andrés",
      description: 'Jeisen Smith Cantillo, un niño de 13 años con síndrome de Down...',
      fullDescription: 'Jeisen Smith Cantillo, un niño de 13 años con síndrome de Down,  estudia en el Colegio Antonia Santos, sede Rubén Darío, donde cursa cuarto grado. Vive en San Andrés junto a su madre, Maritza Smith Mitchell, quien dedica su tiempo y esfuerzos a apoyar su desarrollo. A pesar de los desafíos, Maritza está plenamente comprometida con el proceso educativo y social de su hijo, desempeñando un papel fundamental en su inclusión y participación.',
      video: "https://drive.google.com/file/d/1bA60O9NqgbYtrH2cJeu6yAE8_q-3S7QO/preview"
    },
    { title: "Eje cafetero",
      description: "Brayan Darío Viñez Mejía, un joven de 18 años con discapacidad...",
      fullDescription: "Brayan Darío Viñez Mejía, un joven de 18 años con discapacidad intelectual vive en Dosquebradas, Eje Cafetero, con su mamá, Yenny García; su papá; su hermana mayor y su abuelo. A pesar de las dificultades, la familia ha demostrado un compromiso constante en su desarrollo educativo y social. La mamá de Brayan ha sido clave en su proceso, gestionando su acceso a una institución educativa y apoyándolo activamente en cada paso de su aprendizaje. Antes de llegar a su actual colegio, Brayan enfrentó barreras significativas en otras instituciones, como la falta de avances académicos y frustraciones familiares por la falta de inclusión.",
      video: "https://drive.google.com/file/d/1U6Y5q3bkPDAuRWC6OdH0GJURMOJlrOJq/preview"
    },
    { title: "Antioquia",
      description: "ulieta Londoño Herrera, una niña de 9 años con discapacidad intelectual...",
      fullDescription: "Julieta Londoño Herrera, una niña de 9 años con discapacidad intelectual leve que estudia en una institución en Envigado, Antioquia, desde 2021. Julieta tuvo dificultades académicas, repitiendo el primer grado en 2023, y ha recibido apoyo integral desde la institución educativa, la Fundación Ágora y la Secretaría de Salud.",
      video: "https://drive.google.com/file/d/1E6XlEvhi9Gyz_Tsw0d9x8YcpcF-Hcm6A/preview"
    },
    { title: "Santander",
      description: "Cristian David Galán Zawady es un joven de 20 años con Trastorno del Espectro...",
      fullDescription: "Cristian David Galán Zawady es un joven de 20 años con Trastorno del Espectro Autista (TEA) que vive en Bucaramanga, Santander, Cristian ingresó en 2018 a la Institución Educativa Comuneros, donde cursó el 5° grado. La institución ha creado un entorno inclusivo y seguro, adaptando el currículo a sus necesidades mediante un Plan Individual de Ajustes Razonables (PIAR). Cristian ha participado activamente en actividades artísticas, como la música y la danza, y es valorado y querido por sus compañeros y docentes.",
      video: "https://drive.google.com/file/d/1irTmKJhkFbQuGW5RKQ0udrtYZt4iCqGu/preview"
    },
    { title: "Caribe",
      description: "Jean Carlos Román Cuevas, un estudiante de 15 años, quien, tras ser...",
      fullDescription: "Jean Carlos Román Cuevas, un estudiante de 15 años, quien, tras ser diagnosticado con una enfermedad en diciembre de 2022, tuvo que abandonar su colegio y adaptarse a las Aulas Hospitalarias (AH) de la Clínica del Norte en Barranquilla. La familia, liderada por su madre Aida Cuevas, enfrentó grandes desafíos para garantizar la continuidad educativa de Jean Carlos. Al principio, él se mostró reacio a participar en las AH, pero con el apoyo constante de su madre y docentes, cambió de actitud y logró culminar el octavo grado destacándose especialmente en matemáticas.",
      video: "https://drive.google.com/file/d/1eIzq0e2A5THjeFkSIsyQb1bybpehow5J/preview"
    },
    { title: "Valle del cauca",
      description: "Juan Felipe Paredes Quistial es un joven con un talento excepcional para la música...",
      fullDescription: "Juan Felipe Paredes Quistial es un joven con un talento excepcional para la música. Vive en Palmira, Valle del Cauca, con sus padres y su hermana, y cursa el grado once en una Institución Educativa Oficial (IEO). Desde pequeño ha demostrado habilidades sobresalientes en música, especialmente en instrumentos de viento, percusión y piano, pero también ha enfrentado grandes retos académicos debido a un trastorno específico del aprendizaje, particularmente en asignaturas como física y matemáticas.",
      video: "https://drive.google.com/file/d/1GZCSQQiAblXm2bfUhZ0nFr2YugrDCqAC/preview"
    },
    { title: "Arauca",
      description: "Yurissa Posada Ríos, usuaria de la modalidad por ciclos para adultos...",
      fullDescription: "Yurissa Posada Ríos, usuaria de la modalidad por ciclos para adultos, está matriculada en la Institución Educativa Camilo Torres en la ciudad de Arauca. Con 22 años, cursa el ciclo de educación básica, equivalente al grado octavo, con una modalidad semipresencial que le permite asistir en la jornada sabatina. Durante su infancia, Yurissa asistió al colegio, pero no se implementaron acciones de flexibilización que favorecieran sus habilidades, lo que dificultó su proceso educativo. Yurissa tiene síndrome de Down y en 2023, Yurissa comenzó su trayectoria en los paralímpicos de atletismo.",
      video: "https://drive.google.com/file/d/1Dy7HbP5l4cYiwow5n6o7t3uOy0kUoT5U/preview"
    },
    { title: "Tumaco",
      description: "Evelin es una joven apasionada por la danza, que desde pequeña...",
      fullDescription: "Evelin es una joven apasionada por la danza, que desde pequeña mostró un talento excepcional para moverse al ritmo de la música, lo que sorprendió a su familia y educadores. Su madre ha sido su mayor apoyo, motivándola a seguir su sueño a pesar de los desafíos. La colaboración con su escuela y la Casa Lúdica ha sido clave para su desarrollo, brindándole un espacio fuera del aula tradicional donde ha podido explorar y crecer artísticamente.",
      video: "https://drive.google.com/file/d/1U-bYC4MeNGTAaDbVRMQQow-Ecu70UgiW/preview"
    },
    { title: "Cesar",
      description: "Alejandra Isabel Martínez Campo, estudiante de la Institución Educativa...",
      fullDescription: "Alejandra Isabel Martínez Campo, estudiante de la Institución Educativa Guillermo León Valencia, ha mostrado un progreso significativo gracias a los ajustes razonables y apoyos implementados por los docentes. A pesar de las barreras iniciales, como su dificultad para comunicarse verbalmente, Alejandra ha desarrollado habilidades destacadas en el área artística, especialmente en dibujo y coloreado. También participa con entusiasmo en actividades como las pijamadas literarias y demuestra interés en aprender.",
      video: "https://drive.google.com/file/d/1zRVluqWa3Hb3ZBsi9ORoQsqQbzVQJYD8/preview"
    },
    { title: "Andina- Funza",
      description: "Juan Gabriel García Jaimes es un joven ciego de 14 años que cursa...",
      fullDescription: "Juan Gabriel García Jaimes es un joven ciego de 14 años que cursa octavo grado en la Institución Educativa Técnica Bicentenario. A Juan Gabriel Le apasiona la robótica, el deporte (especialmente nadar) y su proyecto de vida es convertirse en abogado para ayudar a las personas. Recientemente, participó en eventos internacionales como la EXPO LONDON INVENTION y los Juegos Juveniles Nacionales, donde destacó en paraatletismo.",
      video: "https://drive.google.com/file/d/18suVD4u4HXipsxdy_N5SNsgyNTO9uJxQ/preview"
    },
    { title: "Andina- Duitama",
      description: "Loren Sofía nació en Bogotá el 12 de septiembre es usuaria de Lengua...",
      fullDescription: "Loren Sofía nació en Bogotá el 12 de septiembre es usuaria de Lengua de Señas Colombiana. Su familia se mudó a Duitama debido a la contingencia por COVID-19, y Loren ingresó al Instituto Técnico Industrial Reyes, donde comenzó su aprendizaje de la lengua de señas y se sometió a un implante coclear bilateral. Su tránsito educativo estuvo marcado por retos como exclusión, bullying y dificultades emocionales, pero Loren se mantiene motivada por su deseo de ser científica, aprender nuevos idiomas y estudiar en el extranjero. Su familia y modelos lingüísticos han sido su mayor apoyo, ayudándola a superar sus dificultades.",
      video: "https://drive.google.com/file/d/1YJcbwPDnKiCMKeTJMkpT6ei1HNIsQPLW/preview"
    },
    { title: "Nariño - Pasto",
      description: "Sebastián Barrera es un estudiante de grado once,  representante al consejo...",
      fullDescription: "Sebastián Barrera es un estudiante de grado once,  representante al consejo estudiantil y director de un bloque en la banda marcial de su institución. Su trayectoria en el colegio comenzó en 2019, cuando ingresó a sexto grado enfrentando grandes desafíos: no sabía leer ni escribir. Gracias al apoyo del colegio, Sebastián tuvo acceso a un aula de alfabetización y recibió material adicional para reforzar su aprendizaje en casa. Hoy, sus compañeros lo describen como una persona disciplinada  y comprometida. En su tiempo libre, Sebastián se dedica al ciclomontañismo, cursa un técnico en sistemas y trabaja en un taller de artesanías.",
      video: "https://drive.google.com/file/d/1I62kyM_EuJMSnMo6JWe21dLIygiF7b7v/preview"
    },

  ];

  return (
    <>

      {/* Banner section */}
      <Grid container className='Voces-banner-container'>
        <Grid item xs={12} lg={4} className='logos-left'>
          <img className="logo-1" alt="Logo Ministerio de educación" src={logo_educacion} />
        </Grid>
        <Grid item xs={12} lg={8} className='content-right'>
          <h1 className='title'><b>Voces de las familias y estudiantes</b></h1>
        </Grid>
      </Grid>

      {/* Description section */}
      <Grid container className='Voces-description-container backContraste'>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11} lg={10}>
            <Typography className='text-info'>
              Las <b>voces</b> de las <b>familias</b> y de los <b>estudiantes</b> expresan no sólo los saberes, sino también los <b>sentires y pensares</b> que han emergido desde las experiencias vividas en su paso por la educación, por lo mismo, estas construcciones revelan caminos fundamentales a tener en cuenta para una <b>educación rica en diversidad</b>, que garantice los derechos de los sujetos, grupos y comunidades por un proyecto de vida digno.
            </Typography>
            <Typography className='text-info'>
              Las familias como eje dinamizador de la escuela, tienen un rol clave, y necesitan ser convocadas como co-participes de la educación de sus hijos, hijas y familiares, que faciliten las transformaciones de las prácticas, las políticas y las culturas; de otra parte, el tejido que se pudiera lograr fruto del diálogo e intercambio de saberes entre las familias es otro escenario de gran riqueza que permite que padres, madres y familiares se nutran de las experiencias de otros, logren fortalecer el sentido de comunidad, se empoderen, caminen desde la corresponsabilidad con la escuela, y con todo ello puedan ser agentes activos de transformación a favor del valor de la diversidad.
            </Typography>
            <Typography className='text-info'>
              El reconocimiento y la valía de las voces de las y los estudiantes desde una perspectiva de coconstrucción es un camino seguro de encuentro con la diversidad en clave de equidad, derecho, justicia social en la educación. Esta oportunidad de hacerles agentes activos en la escuela les pone en un lugar de privilegio que los reconoce como sujetos políticos hacedores de su propia historia y mentores de su propio futuro en diálogo con otros actores de la comunidad educativa, los pone además en clave de disfrute y goce del derecho a la educación y con ello su emancipación y actuación en la sociedad.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Testimonios section */}
      <Grid container className='Voces-testimonios-container backContraste' justifyContent={'center'}>
        <Grid item xs={12} lg={8} className='sections-container'>
          <Grid xs={11} lg={5}>
            <Typography className='title-2 border-right' onClick={() => toggleItem(0)} id="item-0">
              Familias
            </Typography>
          </Grid>
          <Grid xs={11} lg={5}>
            <Typography className='title-2' onClick={() => toggleItem(1)} id="item-1">
              Estudiantes
            </Typography>
          </Grid>
        </Grid>

        {/* Familias */}
        {openItem === 0 &&
          <>
            {/* Historias y videos experienciales */}
            <Grid container className='Historias-container backContraste'>
              <Grid item xs={12} className='text-container'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Historias y videos experienciales
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid item xs={11} lg={10}>
                  <Typography className='text-info'>
                    Las familias son el corazón de la educación inclusiva. En estos videos, conocerás sus experiencias, desafíos y logros, mostrando cómo el compromiso y la colaboración transforman vidas y construyen un futuro más equitativo para sus hijos.
                  </Typography>
                </Grid>
              </Grid>
              <Swiper
                modules={[Navigation]}
                navigation
                spaceBetween={60}
                slidesPerView={1}
                breakpoints={{
                  1024: { slidesPerView: 4 },
                  600: { slidesPerView: 1 },
                }}
                pagination={{ clickable: true }}
                className='historias-carousel'
              >
                {familias_info.map((item, index) => (
                  <SwiperSlide key={index} className='historias-carousel-slide'>
                    <CardHistorias
                      title={item.title}
                      imgDesc="video experencial"
                      text={item.description}
                      fullText={item.fullDescription}
                      video={item.video}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>

            {/* Testimonios que transforman y conectan comunidades */}
            <Grid container className='Testimony-container backContraste'>
              <Grid container justifyContent={'center'}>
                <Grid xs={11}>
                  <hr className='separator'/>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Testimonios que transforman y conectan comunidades
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid item xs={11} lg={10}>
                  <Typography className='text-info'>
                    Las familias comparten sus experiencias de inclusión educativa, mostrando cómo el trabajo conjunto y el compromiso han transformado el aprendizaje y la vida de sus hijos, fortaleciendo el camino hacia un futuro más equitativo.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className='cards-testimonios-container'>
                <Grid item xs={12} lg={3} className='card-testimonios-space'>
                  <CardTestimonios
                    title="NUESTRA ESCUELA"
                    img={testimony1F}
                    imgDesc="Imagen testimonio"
                    autor=""
                    testimonio={1}
                    type="familias"
                  />
                </Grid>
                <Grid item xs={12} lg={3} className='card-testimonios-space'>
                  <CardTestimonios
                    title="ENCUENTRO REGIONAL DE FAMILIAS"
                    img={testimony2F}
                    imgDesc="Imagen testimonio"
                    autor="Por Olga Cristina Rodríguez"
                    testimonio={2}
                    type="familias"
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Manifiesto section */}
            <Grid container className='Voces-manifiesto-container backContraste'>
              <Grid container justifyContent={'center'}>
                <Grid xs={11}>
                  <hr className='separator'/>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Manifiesto nacional de familias
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid item xs={11} lg={10}>
                  <Typography className='text-info'>
                    Este manifiesto reúne el sentir y las reflexiones de las familias, quienes, desde su experiencia, exigen y promueven una educación que respete, valore y atienda la diversidad. Es un llamado a construir, juntos, un sistema educativo más equitativo y accesible para todos.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} justifyContent={'center'}>
                <Grid xs={11} lg={10} className='img-container'>
                  {/*<img src={manifiesto} alt='Manifiesto' className='info-img' onClick={() => handleOpen()}/>*/}
                  <iframe src={manifiestof} className='info-img'></iframe>
                </Grid>
              </Grid>
              <Dialog open={open} onClose={handleClose} maxWidth='lg'>
                <DialogContent style={{ padding: 0 }}>
                  <img
                    src={manifiesto}
                    alt='Imagen ampliada'
                    style={{
                      width: '100%',
                      height: 'auto',
                      display: 'block',
                    }}
                  />
                </DialogContent>
              </Dialog>
            </Grid>
          </>
        }

        {/* Estudiantes */}
        {openItem === 1 &&
          <>
            {/* Historias y videos experienciales */}
            <Grid container className='Historias-container backContraste'>
              <Grid item xs={12} className='text-container'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Historias y videos experienciales
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid item xs={11} lg={10}>
                  <Typography className='text-info'>
                    Los estudiantes nos cuentan, desde su propia voz, cómo la inclusión educativa ha marcado la diferencia en su aprendizaje y desarrollo. Estas historias reflejan sueños alcanzados, barreras superadas y el poder de creer en un aula para todos.
                  </Typography>
                </Grid>
              </Grid>
              <Swiper
                modules={[Navigation]}
                navigation
                spaceBetween={60}
                slidesPerView={1}
                breakpoints={{
                  1024: { slidesPerView: 4 },
                  600: { slidesPerView: 1 },
                }}
                pagination={{ clickable: true }}
                className='historias-carousel backContraste'
              >
                {estudiantes_info.map((item, index) => (
                  <SwiperSlide key={index} className='historias-carousel-slide'>
                    <CardHistorias
                      title={item.title}
                      imgDesc="video experencial"
                      text={item.description}
                      fullText={item.fullDescription}
                      video={item.video}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>

            {/* Testimonios que transforman y conectan comunidades */}
            <Grid container className='Testimony-container backContraste'>
              <Grid container justifyContent={'center'}>
                <Grid xs={11}>
                  <hr className='separator'/>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Testimonios que transforman y conectan comunidades
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid item xs={11} lg={10}>
                  <Typography className='text-info'>
                    Los estudiantes cuentan cómo la inclusión educativa ha marcado la diferencia en sus vidas, demostrando que el respeto a la diversidad y el apoyo adecuado pueden abrir nuevas oportunidades y sueños cumplidos.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className='cards-testimonios-container'>
                <Grid item xs={12} lg={3} className='card-testimonios-space'>
                  <CardTestimonios
                    title="LLEVAR EL LEGADO A LA EDUCACIÓN SUPERIOR"
                    img={testimony1E}
                    imgDesc="Imagen testimonio"
                    autor=""
                    testimonio={1}
                    type="estudiantes"
                  />
                </Grid>
                <Grid item xs={12} lg={3} className='card-testimonios-space'>
                  <CardTestimonios
                    title="SENTIRES DE LOS ESTUDIANTES "
                    img={testimony2E}
                    imgDesc="Imagen testimonio"
                    autor=""
                    testimonio={2}
                    type="estudiantes"
                  />
                </Grid>
                <Grid item xs={12} lg={3} className='card-testimonios-space'>
                  <CardTestimonios
                    title="LA EDUCACIÓN INCLUSIVA: PROCESO CONTINUO DE TRANSFORMACIÓN CULTURAL, DE PRÁCTICAS Y POLÍTICAS."
                    img={testimony3E}
                    imgDesc="Imagen testimonio"
                    autor="Por Pilar Taramuel"
                    testimonio={3}
                    type="estudiantes"
                  />
                </Grid>
                <Grid item xs={12} lg={3} className='card-testimonios-space'>
                  <CardTestimonios
                    title="NOTA REFLEXIVA SOBRE EL VIDEO DE UN NIÑO CON TALENTOS EXCEPCIONALES"
                    img={testimony4E}
                    imgDesc="Imagen testimonio"
                    autor=""
                    testimonio={4}
                    type="estudiantes"
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Manifiesto section */}
            <Grid container className='Voces-manifiesto-container backContraste'>
              <Grid container justifyContent={'center'}>
                <Grid xs={11}>
                  <hr className='separator'/>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Manifiesto nacional de estudiantes
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className='text-container'>
                <Grid item xs={11} lg={10}>
                  <Typography className='text-info'>
                    Este manifiesto refleja las voces de los estudiantes, sus sueños y su compromiso con una educación inclusiva. Es una declaración de sus derechos, aspiraciones y el deseo de ser reconocidos como agentes activos en la transformación educativa.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} justifyContent={'center'}>
                <Grid xs={11} lg={10} className='img-container'>
                  {/*<img src={manifiesto} alt='Manifiesto' className='info-img' onClick={() => handleOpen2()}/>*/}
                  <iframe src={manifiestoe} className='info-img'></iframe>
                </Grid>
              </Grid>
              <Dialog open={open2} onClose={handleClose2} maxWidth='lg'>
                <DialogContent style={{ padding: 0 }}>
                  <img
                    src={manifiesto}
                    alt='Imagen ampliada'
                    style={{
                      width: '100%',
                      height: 'auto',
                      display: 'block',
                    }}
                  />
                </DialogContent>
              </Dialog>
            </Grid>
          </>
        }

      </Grid>

    </>
  );
};